import React from 'react';
import PropTypes from 'prop-types';
import styles from './separator-container.scss';

const SeparatorContainer = ({ children }) => <div className={styles.container}>{children}</div>;

SeparatorContainer.propTypes = {
  children: PropTypes.any,
};

export default SeparatorContainer;
