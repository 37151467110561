import PropTypes from 'prop-types';
import React from 'react';
import { HorizontalSeparator } from '../separator';
import PostListItem from '../post-list-item';
import CreatePostCta from '../create-post-cta';
import ItemContainer from '../post-list-mobile-common/item-container';
import SeparatorContainer from './separator-container';

const PostListMobileClassic = ({
  category,
  posts,
  query,
  onLikeClick,
  showCreatePostAction,
  showCategoryLink,
  showPinIcon,
  showLockIcon,
  showTopComment,
  showMoreButton,
  showViewCount,
  fullWidthSeparator,
}) => (
  <div>
    {showCreatePostAction && (
      <ItemContainer>
        <CreatePostCta categorySlug={category.slug} />
      </ItemContainer>
    )}
    <ItemContainer>
      {posts.map((post, idx) => (
        <React.Fragment key={post._id}>
          <PostListItem
            post={post}
            query={query}
            type={PostListItem.MOBILE}
            onLikeClick={onLikeClick}
            showCategoryLink={showCategoryLink}
            showContent={false}
            showPinIcon={showPinIcon}
            showLockIcon={showLockIcon}
            showTopComment={showTopComment}
            showMoreButton={showMoreButton}
            showViewCount={showViewCount}
            showSeparator={false}
          />
          {posts.length - 1 !== idx ? (
            fullWidthSeparator ? (
              <HorizontalSeparator />
            ) : (
              <SeparatorContainer>
                <HorizontalSeparator />
              </SeparatorContainer>
            )
          ) : null}
        </React.Fragment>
      ))}
    </ItemContainer>
  </div>
);

PostListMobileClassic.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  showCreatePostAction: PropTypes.bool,
  showCategoryLink: PropTypes.bool,
  showPinIcon: PropTypes.bool,
  showLockIcon: PropTypes.bool,
  showTopComment: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showViewCount: PropTypes.bool,
  fullWidthSeparator: PropTypes.bool,
};

PostListMobileClassic.defaultProps = {
  showPinIcon: true,
  showLockIcon: true,
  showTopComment: true,
  showMoreButton: true,
  showViewCount: true,
};

export default PostListMobileClassic;
