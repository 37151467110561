import React from 'react';

export const XSmall = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path d="M16.2307692,7 L17,7.76923077 L12.769,12 L17,16.2307692 L16.2307692,17 L12,12.769 L7.76923077,17 L7,16.2307692 L11.23,12 L7,7.76923077 L7.76923077,7 L12,11.231 L16.2307692,7 Z" />
  </svg>
);
