import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import EmptyStates from '../empty-states';
import withTranslate from '../../hoc/with-translate';
import { LockEmptyIcon } from '../icons/lock-empty-icon';
import styles from './logged-in-no-access-empty-state.scss';

const LoggedInNoAccessEmptyState = ({ t }) => (
  <EmptyStates
    title={
      <span className={styles.title}>
        {t('private-category-restriction-all.title')}
        <LockEmptyIcon className={classNames(styles.lockIcon, 'forum-icon-fill')} />
      </span>
    }
    content={t('private-category-restriction-all.content')}
    type="all_categories_hidden"
  />
);

LoggedInNoAccessEmptyState.propTypes = {
  t: PropTypes.func,
};

export default flowRight(withTranslate)(LoggedInNoAccessEmptyState);
