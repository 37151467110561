import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { COVER_TYPE_IMAGE } from '../../constants/cover-types';
import Link from '../link/internal-link';
import PageCover from '../page-cover';
import withTranslate from '../../hoc/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withAuth from '../../hoc/with-auth';
import styles from './category-list-item.scss';
import { getSubcategories } from '../../../common/selectors/categories-selectors';
import { getIsShowSubcategoriesEnabled } from '../../selectors/app-settings-selectors';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import { HorizontalSeparator } from '../separator';
import SubcategoryListItem from '../subcategory-list-item';
import { isPrivate } from '../../../common/services/category-privacy-utils';
import { LockEmptyIcon } from '../icons/lock-empty-icon';
import { CurrencySignIcon } from '../icons/currency-sign-icon';
import { doesUserHavePermissionsToSeeCategoryActions } from '../../containers/category-actions/category-actions';
import { hasCategoryPaidPlans } from '../../selectors/paid-plans-selectors';
import withPermissions from '../../hoc/with-permissions';
import { MODAL_TYPE_CATEGORY_ACTIONS } from '../modals/action-sheet/action-sheet-modal-types';
import MoreButtonMobile from '../more-button-mobile';
import ShadowContainer from '../container/shadow-container';

const COVER_WIDTH = 320;
const COVER_HEIGHT = 150;

class CategoryListItem extends Component {
  render() {
    const {
      category = {},
      t,
      titleFontClassName,
      contentFontClassName,
      borderWidth,
      isViewsCountEnabled,
      isShowSubcategoriesEnabled,
      subcategories,
      showViewCount,
      hasCategoryPP,
      can,
    } = this.props;
    const containerClassName = classNames(
      styles.container,
      'forum-card-background-color',
      'forum-card-border-color',
      'category-list-item',
    );
    const titleClassName = classNames(
      styles.title,
      titleFontClassName,
      'category-list-item__title',
    );
    const statisticsClassName = classNames(
      styles.statistics,
      contentFontClassName,
      'category-list-item__statistics',
    );
    const style = { borderWidth };
    const categoryLink = `/${category.slug}`;

    const linkClassName = classNames(styles.overlay, 'category-list-item__link');

    let categoryBadge = null;
    if (isPrivate(category)) {
      const Icon = hasCategoryPP ? CurrencySignIcon : LockEmptyIcon;
      categoryBadge = <Icon className="forum-icon-fill" />;
    }

    return (
      <ShadowContainer>
        <div className={containerClassName} style={style} data-hook="category-list-item">
          {category.coverType === COVER_TYPE_IMAGE && (
            <div className={styles.cover}>
              <PageCover
                cover={category.cover}
                coverType={category.coverType}
                width={COVER_WIDTH}
                height={COVER_HEIGHT}
              >
                <Link className={linkClassName} to={categoryLink} renderInSeo={false} />
              </PageCover>
            </div>
          )}
          <div className={classNames(styles.details, 'forum-text-color')}>
            <div className={styles.titleWrapper}>
              <h2 className={titleClassName} data-hook="category-list-item__title">
                <Link className={styles.categoryLink} to={categoryLink}>
                  {category.label}
                </Link>
              </h2>
              <p className={statisticsClassName}>
                <span>
                  {t('category-list-item.total-posts', { count: category.totalPosts || 0 })}
                </span>
                {isViewsCountEnabled && showViewCount && (
                  <span>
                    {t('category-list-item.total-views', { count: category.totalViews || 0 })}
                  </span>
                )}
              </p>
            </div>
            <div className={styles.buttonGroup}>
              {categoryBadge}
              {doesUserHavePermissionsToSeeCategoryActions(can, category) && (
                <MoreButtonMobile
                  size={MoreButtonMobile.SIZE_SMALL}
                  type={MODAL_TYPE_CATEGORY_ACTIONS}
                  category={category}
                />
              )}
            </div>
          </div>
          {isShowSubcategoriesEnabled && subcategories.length > 0 && (
            <div>
              <HorizontalSeparator className={styles.separatorLong} />
              {subcategories.map((subcategory, idx) => (
                <div key={subcategory._id}>
                  {idx > 0 && <HorizontalSeparator className={styles.separatorShort} />}
                  <SubcategoryListItem
                    category={subcategory}
                    isViewsCountEnabled={isViewsCountEnabled}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </ShadowContainer>
    );
  }
}

CategoryListItem.propTypes = {
  category: PropTypes.object.isRequired,
  t: PropTypes.func,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  borderWidth: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  isViewsCountEnabled: PropTypes.bool,
  showViewCount: PropTypes.bool,
  hasCategoryPP: PropTypes.bool,
  isShowSubcategoriesEnabled: PropTypes.bool,
  subcategories: PropTypes.arrayOf(PropTypes.object),
};

const mapRuntimeToProps = (state, { category }, actions, host) => ({
  isShowSubcategoriesEnabled: getIsShowSubcategoriesEnabled(state, host.style),
  subcategories: getSubcategories(state, category._id),
  showViewCount: !isCacheableRendering(state),
  hasCategoryPP: hasCategoryPaidPlans(state, category.groups),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withAuth,
  withCardBorderWidth,
  withFontClassName,
  withTranslate,
  withPermissions,
)(CategoryListItem);
