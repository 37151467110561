import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { HorizontalSeparator } from '../separator';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './header.scss';

export class Header extends Component {
  render() {
    const { left, right, menu, contentFontClassName, className } = this.props;
    const containerClassName = classNames(
      styles.headerContainer,
      'forum-text-color',
      contentFontClassName,
    );
    const wrapperClassName = classNames(styles.header, 'forum-card-background-color', className);

    return (
      <div className={containerClassName} ref={this.props.headerRef}>
        <HorizontalSeparator />
        <div className={wrapperClassName}>
          {left}
          {right}
        </div>
        {menu ? <HorizontalSeparator /> : null}
        {menu || null}
      </div>
    );
  }
}

Header.propTypes = {
  className: PropTypes.string,
  contentFontClassName: PropTypes.string.isRequired,
  left: PropTypes.node,
  right: PropTypes.node,
  menu: PropTypes.node,
};

Header.defaultProps = {
  left: (
    <div className={styles.left}>
      <span className={styles.menuButton} />
    </div>
  ),
};

export default flowRight(withFontClassName)(Header);
