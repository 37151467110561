import { flowRight, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { PinIcon } from '../icons/pin-icon';
import { LockIcon } from '../icons/lock-icon';
import UserAvatarImage from '../user-avatar-image';
import MoreButton from '../more-button';
import PostTitle from '../post-title';
import TimeAgo from '../time-ago';
import Hashtags from '../hashtags';
import { BulletSeparator as Bullet } from '../separator';
import TopCommentIconLink from '../top-comment-icon-link';
import { QUESTION } from '../../constants/post-types';
import PostActions from '../../containers/post-actions';
import MemberCardAsync from '../../containers/member-card-async';
import { compactNumber } from '../../services/format-number';
import { getCategory } from '../../selectors/categories-selectors';
import {
  getIsListHashtagsEnabled,
  getIsViewsCountEnabled,
} from '../../selectors/app-settings-selectors';
import {
  isCacheableRendering,
  getLanguage,
} from '../../../common/store/basic-params/basic-params-selectors';
import { isQACategoriesExperimentEnabled } from '../../selectors/experiments-selectors';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import styles from './post-list-item-classic.scss';
import RoleBadge from '../role-badge';
import QuickUserInfo from '../quick-user-info/quick-user-info';
import Link from '../link/internal-link';
import ProfileLink from '../link/profile-link';

const BulletSeparator = () => <Bullet className={'forum-text-color'} />;

export class PostListItemClassic extends Component {
  fetchPostDataView = () => {
    const { fetchPostPageData, post } = this.props;
    fetchPostPageData(post.slug, 1);
  };

  renderFooter = owner => {
    const {
      category,
      post,
      showCategoryLink,
      isListHashtagsEnabled,
      isQACategoriesEnabled,
      isMultiplePostTypeCategory,
      t,
    } = this.props;
    const hashtags = get(post, 'hashtags');
    const showHashtags = isListHashtagsEnabled && !isEmpty(hashtags);
    const renderPostType = () => {
      const typeName =
        post.postType === QUESTION
          ? 'post-list-item.question-post-type'
          : 'post-list-item.discussion-post-type';
      return (
        <div className={classNames(styles.postType, 'forum-text-color')}>
          {t(typeName)}
          <BulletSeparator />
        </div>
      );
    };

    return (
      <div className={classNames(styles.footer, 'forum-links-hashtags-color')}>
        {isQACategoriesEnabled && isMultiplePostTypeCategory && renderPostType()}
        {showCategoryLink && (
          <Link
            className={classNames(
              'forum-text-color',
              'forum-link-hover-color',
              styles.categoryLabel,
            )}
            to={`/${category.slug}`}
          >
            {category.label}
          </Link>
        )}
        {showCategoryLink && <BulletSeparator />}
        <MemberCardAsync viewedMemberId={owner.siteMemberId} className={styles.avatarLink}>
          <ProfileLink
            className={classNames(styles.name, 'forum-text-color', 'forum-link-hover-color', {
              [styles.obscure]: showCategoryLink,
            })}
            user={owner}
          >
            {owner.name}
            <RoleBadge user={owner} />
            <div className={classNames(styles.quickInfo, 'forum-text-color')}>
              <QuickUserInfo
                user={owner}
                date={post.createdDate}
                showDate={!isQACategoriesEnabled}
                showBadges
                hideBadgeBackground
                showFirstSeparator
                noMargin
              />
            </div>
          </ProfileLink>
        </MemberCardAsync>
        {showHashtags && <span>&nbsp;&nbsp;</span>}
        {showHashtags && <Hashtags hashtags={hashtags} className={styles.hashtags} />}
      </div>
    );
  };

  render() {
    const {
      category,
      post,
      query,
      titleFontClassName,
      contentFontClassName,
      isViewsCountEnabled,
      showViewCount,
      language,
      isQACategoriesEnabled,
      t,
    } = this.props;
    const className = classNames(
      styles.container,
      'forum-text-color',
      'post-list-item',
      contentFontClassName,
    );
    const owner = post.owner || {};
    const moreButtonId = `more-button-${post._id}`;
    const compactNumberLocal = compactNumber(language);

    return (
      <tr id={post._id} className={className} data-hook="post-list-item">
        <td className={styles.avatar}>
          <MemberCardAsync viewedMemberId={owner.siteMemberId} className={styles.avatarLink}>
            <ProfileLink user={owner}>
              <UserAvatarImage user={owner} />
            </ProfileLink>
          </MemberCardAsync>
        </td>
        <td>
          <div className={styles.titleWithIcons}>
            <Link
              onMouseDown={this.fetchPostDataView}
              className={classNames(titleFontClassName, styles.titleLink)}
              to={`/${category.slug}/${post.slug}`}
            >
              <PostTitle
                className={classNames(
                  titleFontClassName,
                  styles.title,
                  'forum-text-color',
                  'forum-link-hover-color',
                )}
                type={PostTitle.SMALL}
                title={post.title}
                query={query}
                post={post}
                isQACategoriesEnabled={isQACategoriesEnabled}
              />
            </Link>
            {post.isPinned && (
              <PinIcon
                className={classNames(styles.icon, 'icon-secondary-fill')}
                isPinned={post.isPinned}
                aria-label={t('post-list-item.pinned-post')}
              />
            )}
            {post.isCommentsDisabled && (
              <LockIcon
                className={classNames(styles.icon, 'icon-secondary-fill')}
                isLocked={post.isCommentsDisabled}
              />
            )}
            {!isQACategoriesEnabled && Boolean(post.bestAnswerCommendId) && (
              <TopCommentIconLink
                t={t}
                topCommentId={post.bestAnswerCommendId}
                linkComponent={Link}
                toolTipClassName={styles.tooltip}
              />
            )}
          </div>
          {this.renderFooter(owner)}
        </td>
        <td className={classNames(styles.count, styles.right)}>
          {compactNumberLocal(post.totalComments) || 0}
        </td>
        <td className={classNames(styles.count, styles.right)}>
          {compactNumberLocal(post.likeCount) || 0}
        </td>
        {isViewsCountEnabled && (
          <td className={classNames(styles.count, styles.right)}>
            <span data-hook="post-list-item__view-count">
              {showViewCount ? compactNumberLocal(post.viewCount) || 0 : ''}
            </span>
          </td>
        )}
        <td className={classNames(styles.stats, styles.left, styles.recentActivity)}>
          <TimeAgo time={post.lastActivityDate || post.createdDate} />
        </td>
        <td>
          <MoreButton id={moreButtonId}>
            <PostActions post={post} focusOnCloseId={moreButtonId} origin="feed_page" />
          </MoreButton>
        </td>
      </tr>
    );
  }
}

PostListItemClassic.propTypes = {
  t: PropTypes.func,
  category: PropTypes.object,
  post: PropTypes.object.isRequired,
  query: PropTypes.string,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  navigation: PropTypes.object,
  isViewsCountEnabled: PropTypes.bool,
  showCategoryLink: PropTypes.bool,
  isListHashtagsEnabled: PropTypes.bool,
  fetchPostPageData: PropTypes.func,
  showViewCount: PropTypes.func,
  isMultiplePostTypeCategory: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  category: getCategory(state, ownProps.post.categoryId),
  isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
  isListHashtagsEnabled: getIsListHashtagsEnabled(state, host.style),
  fetchPostPageData: actions.fetchPostPageData,
  showViewCount: !isCacheableRendering(state),
  language: getLanguage(state),
  isQACategoriesEnabled: isQACategoriesExperimentEnabled(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withFontClassName,
  withTranslate,
)(PostListItemClassic);
