import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './who-liked-list-header-desktop.scss';
import withDividerColor from '../../hoc/with-divider-color';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';

class WhoLikedListHeaderDesktop extends Component {
  render() {
    const { t, contentFontClassName, dividerColor, totalItemCount } = this.props;
    return (
      <div className={styles.container}>
        <h3
          className={classNames(styles.header, contentFontClassName)}
          style={{ borderColor: dividerColor }}
        >
          {t('who-liked.header-title', { likes: totalItemCount })}
        </h3>
      </div>
    );
  }
}

WhoLikedListHeaderDesktop.propTypes = {
  totalItemCount: PropTypes.number,
  contentFontClassName: PropTypes.number,
};

export default flowRight(
  withFontClassName,
  withDividerColor,
  withTranslate,
)(WhoLikedListHeaderDesktop);
