import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import { getIsViewsCountEnabled } from '../../selectors/app-settings-selectors';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import withTranslate from '../../hoc/with-translate';
import styles from './post-stats.scss';
import TimeAgo from '../time-ago';

function PostStats({ viewCount, commentCount, t, type, isViewsCountEnabled, showViewCount, time }) {
  return (
    <div
      className={classNames(
        {
          [styles.counter]: type === PostStats.DEFAULT,
          [styles.counterMemberArea]: type === PostStats.MEMBER_AREA,
        },
        time && styles.showTime,
        'post-stats',
      )}
    >
      {time && <TimeAgo time={time} />}
      <span className={classNames(styles.commentCount)} data-hook="post-stats__comment-count">
        {t('post-stats.comment-count', { count: commentCount })}
      </span>
      {isViewsCountEnabled && (
        <span className={classNames(styles.viewCount)} data-hook="post-stats__view-count">
          {showViewCount ? t('post-stats.view-count', { count: viewCount }) : ''}
        </span>
      )}
    </div>
  );
}

PostStats.DEFAULT = 'default';
PostStats.MEMBER_AREA = 'memberArea';

PostStats.propTypes = {
  commentCount: PropTypes.number.isRequired,
  viewCount: PropTypes.number.isRequired,
  t: PropTypes.func,
  type: PropTypes.string,
  isViewsCountEnabled: PropTypes.bool,
  time: PropTypes.number,
  showViewCount: PropTypes.bool,
};

PostStats.defaultProps = {
  commentCount: 0,
  viewCount: 0,
  type: PostStats.DEFAULT,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
  showViewCount: !isCacheableRendering(state),
});

export default connect(mapRuntimeToProps)(withTranslate(PostStats));
