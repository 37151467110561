import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import Link from '../link/internal-link';
import { SearchIcon } from '../icons/search-icon';
import withTranslate from '../../hoc/with-translate';
import styles from './search-button.scss';

class SearchButton extends Component {
  render() {
    const { t } = this.props;
    return (
      <Link className={classNames(styles.button, 'search-button')} to="/search">
        <SearchIcon className="forum-icon-fill" />
        <span className={styles.hiddenLabel}>{t('search-button.search')}</span>
      </Link>
    );
  }
}

SearchButton.propTypes = {
  t: PropTypes.func,
};

export default withTranslate(SearchButton);
