import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight, isNumber } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import withAuth from '../../hoc/with-auth';
import { EXPERIMENT_POST_RATE_LIMITER } from '../../constants/experiments';
import withExperiment from '../../hoc/with-experiment';
import { getCurrentUserRemainingPosts } from '../../../common/store/current-user/current-user-selectors';
import { POST_LIMIT_REACHED } from '../../components/messages/message-types';
import { DISCUSSION } from '../../constants/post-types';

class CreatePostHandler extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    categorySlug: PropTypes.string,
    navigateWithinForum: PropTypes.func.isRequired,
    forPublicUser: PropTypes.func.isRequired,
    isPostLimiterExperimentEnabled: PropTypes.bool,
    showMessage: PropTypes.func.isRequired,
    remainingPosts: PropTypes.number,
    postType: PropTypes.string,
  };

  static defaultProps = {
    postType: DISCUSSION,
  };

  handleClick = () => {
    const {
      navigateWithinForum,
      categorySlug,
      isPostLimiterExperimentEnabled,
      showMessage,
      remainingPosts,
      postType,
    } = this.props;
    if (isPostLimiterExperimentEnabled && isNumber(remainingPosts) && remainingPosts < 1) {
      showMessage(POST_LIMIT_REACHED);
      return;
    }
    const postTypeSlug = postType === DISCUSSION ? '/create-post' : '/create-question';
    const path = categorySlug ? `/${categorySlug}${postTypeSlug}` : postTypeSlug;
    navigateWithinForum(path);
  };

  render() {
    const { children, forPublicUser } = this.props;
    const child = React.Children.only(children);
    return React.cloneElement(child, { onClick: forPublicUser(this.handleClick) });
  }
}

const mapRuntimeToToProps = (state, ownProps, actions) => ({
  navigateWithinForum: actions.navigateWithinForum,
  showMessage: actions.showMessage,
  remainingPosts: getCurrentUserRemainingPosts(state),
});

export default flowRight(
  connect(mapRuntimeToToProps),
  withAuth,
  withExperiment({ isPostLimiterExperimentEnabled: EXPERIMENT_POST_RATE_LIMITER }),
)(CreatePostHandler);
