import React from 'react';

export const ShareIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      d="M10.768 5.078L16.931 9l-6.163 3.922A.5.5 0 0 1 10 12.5V11h-.5A5.5 5.5 0 0 0 4 16.5H3v-2a7.5 7.5 0 0 1 7-7.484V5.5a.5.5 0 0 1 .768-.422zM11 8h-.5a6.503 6.503 0 0 0-6.23 4.64A6.49 6.49 0 0 1 9.5 10H11v1.59L15.069 9 11 6.41V8z"
    />
  </svg>
);
