import { uploadFileV2 } from './upload-file';

export const uploadFileToWixMedia = requestCredentialsPromisified => file => {
  return requestCredentialsPromisified(file.name, file.type).then(({ credentials, config }) => {
    return uploadFileV2({
      file,
      credentials,
      name: file.name,
      acl: config.acl,
      path: config.path,
    });
  });

  /* response example
  {
    acl: "private"
    bucket: null
    dateCreated: "2019-06-20T09:41:41Z"
    dateUpdated: "2019-06-20T09:41:41Z"
    hash: "299d273096403e6b58abadb5cbc11689"
    id: "1cc9868c1b8a414cb1d59a5e8440dcad"
    lifecycle: null
    mimeType: "application/x-iso9660-appimage"
    path: "/metasiteIdFolder/1561023677498-OpenShot-v2.4.1-x86_64.AppImage"
    size: 145227776
    type: "-"
    urn: "urn:file:1cc9868c1b8a414cb1d59a5e8440dcad"
  }
  */
};

export const FILE_UPLOAD_SIZE_LIMIT_MB = 500;
