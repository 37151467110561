import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import Link from '../link/internal-link';
import { getForumTitle } from '../../selectors/forum-data-selectors';
import withTranslate from '../../hoc/with-translate';
import styles from './header-title.scss';

export const HeaderTitle = ({ title, t, to }) => {
  const Component = to === undefined ? 'span' : Link;
  return (
    <h1 className={styles.title}>
      <Component to={to}>{title || t('header-title.default-title')}</Component>
    </h1>
  );
};

HeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  to: PropTypes.string,
};

const mapRuntimeToProps = state => ({
  title: getForumTitle(state),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(HeaderTitle);
