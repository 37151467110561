import PropTypes from 'prop-types';
import React from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import AvatarImage from '../avatar-image';
import CurrentUserAvatarImage from '../../containers/current-user-avatar-image';
import { CARD_SIZE_SMALL, CARD_SIZE_MEDIUM } from '../../constants/card-sizes';
import { ArrowDownIcon } from '../icons/arrow-down-icon';
import { EditIcon } from '../icons/edit-icon';
import withAuth from '../../hoc/with-auth';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import styles from './create-post-card.scss';
import { handleEnterAndSpaceKeyUp } from '../../services/accessibility';
import { connect } from '../../../common/components/runtime-context';
import { getCardSize } from '../../selectors/app-settings-selectors';
import ShadowContainer from '../container/shadow-container';

const TYPES = {
  [CARD_SIZE_SMALL]: 'small',
  [CARD_SIZE_MEDIUM]: 'medium',
};

const CreatePostCard = ({
  cardSize,
  isAuthenticated,
  onClick,
  contentFontClassName,
  showQuestionLabel,
  isMultiplePostTypeCategory,
  t,
}) => {
  const type = TYPES[cardSize];
  const label = showQuestionLabel
    ? 'create-post-card.create-new-question'
    : 'create-post-card.create-post';
  const className = classNames(
    styles.button,
    contentFontClassName,
    'button-background-color',
    'button-primary-text-color',
    'create-post',
    { [styles[type]]: type },
  );

  return (
    <ShadowContainer>
      <div
        className={className}
        onClick={onClick}
        tabIndex="0"
        onKeyUp={handleEnterAndSpaceKeyUp(onClick)}
        data-hook="create-post-cta"
        role="button"
        aria-labelledby="create-post-label"
      >
        {isAuthenticated ? <CurrentUserAvatarImage /> : <AvatarImage />}
        <div id="create-post-label" className={classNames(styles.text, 'button-primary-icon-fill')}>
          {t(label)}
          {isMultiplePostTypeCategory ? <ArrowDownIcon /> : <EditIcon />}
        </div>
      </div>
    </ShadowContainer>
  );
};

CreatePostCard.propTypes = {
  t: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  cardSize: PropTypes.number,
  onClick: PropTypes.func,
  contentFontClassName: PropTypes.string.isRequired,
  isMultiplePostTypeCategory: PropTypes.bool,
  showQuestionLabel: PropTypes.bool,
};

CreatePostCard.defaultProps = {
  cardSize: CARD_SIZE_SMALL,
  showQuestionLabel: false,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  cardSize: getCardSize(state, host.style),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withAuth,
  withFontClassName,
  withTranslate,
)(CreatePostCard);
