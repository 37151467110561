import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import PostStats from '../post-stats';
import styles from './post-page-stats.scss';

const ANIMATION_TIMEOUT = 1200;

const PostPageStats = ({ viewCount, commentCount, isAnimated, time }) => {
  return (
    <CSSTransitionGroup
      transitionName={styles}
      transitionEnterTimeout={ANIMATION_TIMEOUT}
      transitionAppearTimeout={ANIMATION_TIMEOUT}
      transitionLeaveTimeout={0}
      transitionAppear={isAnimated}
      component="div"
    >
      <div key="stats">
        <div className={styles.container}>
          <PostStats viewCount={viewCount} commentCount={commentCount} time={time} />
        </div>
      </div>
    </CSSTransitionGroup>
  );
};

PostPageStats.propTypes = {
  viewCount: PropTypes.number,
  commentCount: PropTypes.number,
  isAnimated: PropTypes.bool,
  time: PropTypes.number,
};

export default PostPageStats;
