import PropTypes from 'prop-types';
import React from 'react';
import withTranslate from '../../hoc/with-translate';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './no-search-results.scss';
import EmptyStates from '../empty-states';

export const NoSearchResults = ({ t, borderWidth }) => (
  <div style={{ marginTop: -borderWidth }}>
    <EmptyStates
      className={styles.container}
      title={t('no-search-results.no-results-found-title')}
      content={t('no-search-results.try-again')}
    />
  </div>
);

NoSearchResults.propTypes = {
  borderWidth: PropTypes.number.isRequired,
  t: PropTypes.func,
};

export default withTranslate(withCardBorderWidth(withFontClassName(NoSearchResults)));
