import { AvatarGroup as WUTAvatarGroup } from 'wix-ui-tpa/AvatarGroup';
import React from 'react';
import styles from './avatar-group.st.css';

const AvatarGroup = ({ items = [], totalItemCount, onClick, limit = 3, isMobile }) => (
  <div onClick={onClick}>
    <WUTAvatarGroup
      {...styles('root')}
      size="xxSmall"
      items={items.slice(0, limit).map(item => ({ name: item.name, src: item.image }))}
      maxAmount={limit}
    >
      {totalItemCount - limit > 0 && (
        <WUTAvatarGroup.TextButton style={isMobile ? { fontSize: '12px' } : {}}>
          +{totalItemCount - limit}
        </WUTAvatarGroup.TextButton>
      )}
    </WUTAvatarGroup>
  </div>
);

export default AvatarGroup;
