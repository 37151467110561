import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import { getIsViewsCountEnabled } from '../../selectors/app-settings-selectors';
import { DISCUSSION } from '../../constants/post-types';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import withTranslate from '../../hoc/with-translate';
import styles from './post-stats.scss';

function PostStats({
  viewCount,
  commentCount,
  replyCount,
  t,
  type,
  isViewsCountEnabled,
  showViewCount,
  postType,
}) {
  return (
    <div
      className={classNames(
        {
          [styles.counter]: type === PostStats.DEFAULT,
          [styles.counterMemberArea]: type === PostStats.MEMBER_AREA,
        },
        'post-stats',
      )}
    >
      {isViewsCountEnabled && (
        <span className={classNames(styles.viewCount)} data-hook="post-stats__view-count">
          {showViewCount ? t('post-stats.view-count', { count: viewCount }) : ''}
        </span>
      )}
      <span className={classNames(styles.commentCount)} data-hook="post-stats__comment-count">
        {t(postType === DISCUSSION ? 'post-stats.comment-count' : 'post-stats.answer-count', {
          count: commentCount,
        })}
      </span>
      <span className={classNames(styles.replyCount)} data-hook="post-stats__reply-count">
        {t('post-stats.reply-count', { count: replyCount })}
      </span>
    </div>
  );
}

PostStats.DEFAULT = 'default';
PostStats.MEMBER_AREA = 'memberArea';

PostStats.propTypes = {
  commentCount: PropTypes.number.isRequired,
  replyCount: PropTypes.number.isRequired,
  viewCount: PropTypes.number.isRequired,
  t: PropTypes.func,
  type: PropTypes.string,
  isViewsCountEnabled: PropTypes.bool,
  showViewCount: PropTypes.bool,
  postType: PropTypes.string,
};

PostStats.defaultProps = {
  commentCount: 0,
  viewCount: 0,
  type: PostStats.DEFAULT,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
  showViewCount: !isCacheableRendering(state),
});

export default connect(mapRuntimeToProps)(withTranslate(PostStats));
