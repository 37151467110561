import PropTypes from 'prop-types';
import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { connect } from '../../common/components/runtime-context';
import createHocName from '../services/create-hoc-name';
import { omit, flowRight } from 'lodash';
import {
  getCurrentUser,
  isBlocked,
  isOwner,
  isPrivate,
} from '../../common/store/current-user/current-user-selectors';
import { isMemberAreaActive } from '../selectors/member-area-selectors';
import { isSite } from '../../common/store/basic-params/basic-params-selectors';

const NAME = 'withAuth';

export default function withAuth(WrappedComponent) {
  class WithAuth extends Component {
    static displayName = createHocName(NAME, WrappedComponent);
    static propTypes = {
      showJoinCommunityPopup: PropTypes.func,
      isPrivate: PropTypes.bool,
      isAuthenticated: PropTypes.bool,
      __isMemberAreaActive: PropTypes.bool,
      __isSite: PropTypes.bool,
    };

    forPublicUser = (fn, options) => {
      if (!this.props.__isMemberAreaActive || !this.props.__isSite) {
        return fn;
      }
      return this.decorateWithPrivacyChecker(fn, options);
    };

    decorateWithPrivacyChecker = (fn, { preventDefault = false } = {}) => {
      const isPrivate = () => this.props.isPrivate;
      const isAuthenticated = () => this.props.isAuthenticated;
      const showJoinCommunityPopup = () => this.props.showJoinCommunityPopup();

      return function(...args) {
        if (preventDefault) {
          const event = args[0];
          event.preventDefault && event.preventDefault();
        }

        if (isAuthenticated() && isPrivate()) {
          showJoinCommunityPopup();
        } else {
          return fn.apply(this, args);
        }
      };
    };

    render() {
      const props = omit({ forPublicUser: this.forPublicUser, ...this.props }, [
        '__isMemberAreaActive',
        '__isSite',
        'showJoinCommunityPopup',
      ]);
      return <WrappedComponent {...props} />;
    }
  }

  hoistNonReactStatics(WithAuth, WrappedComponent);

  return flowRight(connect(mapRuntimeToProps))(WithAuth);
}

function mapRuntimeToProps(state, ownProps, actions) {
  return {
    showJoinCommunityPopup: actions.showJoinCommunityPopup,
    currentUser: getCurrentUser(state),
    isAuthenticated: Boolean(state.currentUser),
    isBlocked: isBlocked(state),
    isOwner: isOwner(state),
    isPrivate: isPrivate(state),
    __isMemberAreaActive: isMemberAreaActive(state),
    __isSite: isSite(state),
  };
}
