import { connect } from '../../../common/components/runtime-context';
import PostSocialActions from '../../components/post-social-actions';
import { MODAL_TYPE_SHARE_POST } from '../../components/modals/share-post-modal/share-post-modal-type';
import { getRouteParams } from '../../../common/router/router-selectors';

const mapRuntimeToProps = (state, ownProps, actions) => ({
  openShareLinkModal: () => {
    const routeParams = getRouteParams(state);

    return actions.openModal(MODAL_TYPE_SHARE_POST, {
      isLinkShareFormVisible: true,
      categorySlug: routeParams.categorySlug,
      postSlug: routeParams.postSlug,
    });
  },
});

export default connect(mapRuntimeToProps)(PostSocialActions);
