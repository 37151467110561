import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import {
  ROUTE_404,
  ROUTE_SEARCH,
  ROUTE_LOGIN,
  ROUTE_ACCOUNT_SUSPENDED,
  ROUTE_CREATE_POST,
  ROUTE_CREATE_QUESTION,
  ROUTE_COMMENT_DEEP_LINK,
  ROUTE_CATEGORY,
  ROUTE_CATEGORY_CREATE_POST,
  ROUTE_CATEGORY_CREATE_QUESTION,
  ROUTE_POST,
  ROUTE_POST_EDIT,
  ROUTE_HOME,
} from '../../constants/routes';
import Link from '../link/internal-link';
import ArrowIcon from './arrow-icon';
import { getForumData, getForumTitle } from '../../selectors/forum-data-selectors';
import { getRoute, getRouteParams } from '../../../common/router/router-selectors';
import withTranslate from '../../hoc/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './breadcrumbs.scss';
import {
  getCategoryBySlug,
  getCategory,
  getAllCategories,
  getAllCategoryCount,
} from '../../selectors/categories-selectors';
import { getPostBySlug } from '../../selectors/post-selectors';
import { getSectionUrl } from '../../../common/store/location/location-selectors';
import { isPreview, isEditor } from '../../../common/store/basic-params/basic-params-selectors';

const isSanta = typeof boltInstance === 'undefined';

function buildCategoryBreadcrumbs({
  state,
  params,
  categoryIsLink = true,
  santaNavigationToHomeIssueWorkaroundHack,
}) {
  const category = getCategoryBySlug(state, params.categorySlug) || {};
  let parentCategory;
  if (category.parentId) {
    parentCategory = getCategory(state, category.parentId);
  }
  const categoryCount = getAllCategoryCount(state);
  const builder = new BreadcrumbsBuilder();
  if (categoryCount > 1) {
    builder.addLink('/', getForumData(state).label, santaNavigationToHomeIssueWorkaroundHack);
    builder.addSeparator();
  }
  if (parentCategory) {
    builder.addLink(`/${parentCategory.slug}`, parentCategory.label);
    builder.addSeparator();
  }
  if (categoryIsLink) {
    builder.addLink(`/${category.slug}`, category.label);
  } else {
    builder.addLast(category.label);
  }

  return builder;
}

class BreadcrumbsBuilder {
  constructor() {
    this.breadcrumbs = [];
    this.index = 0;
  }

  addLast(text) {
    const index = ++this.index;
    this.breadcrumbs.push(
      <li>
        <span
          tabIndex={0}
          aria-current="page"
          key={index}
          data-hook="breadcrumbs__current-page"
          role="link"
        >
          {text}
        </span>
      </li>,
    );
  }

  addLink(link, text, santaNavigationToHomeIssueWorkaroundHack) {
    const index = ++this.index;
    this.breadcrumbs.push(
      <li>
        <Link
          to={link}
          key={index}
          data-hook={`breadcrumbs__${index}`}
          dynamicLocationResolver={santaNavigationToHomeIssueWorkaroundHack}
        >
          {text}
        </Link>
      </li>,
    );
  }

  addSeparator() {
    const index = ++this.index;
    this.breadcrumbs.push(
      <span className={styles.separator} key={`separator-${index}`}>
        <ArrowIcon className="breadcrumbs-icon-fill" />
      </span>,
    );
  }

  build() {
    return this.breadcrumbs;
  }
}

const ROUTE_BREADCRUMBS_FACTORY_MAP = {
  [ROUTE_404]: ({ state }) => {
    const builder = new BreadcrumbsBuilder();
    builder.addLink('/', getForumData(state).label);
    return builder.build();
  },
  [ROUTE_POST]: ({ state, params, santaNavigationToHomeIssueWorkaroundHack }) => {
    const builder = buildCategoryBreadcrumbs({
      state,
      params,
      santaNavigationToHomeIssueWorkaroundHack,
    });
    const post = getPostBySlug(state, params.postSlug);
    if (post) {
      builder.addSeparator();
      builder.addLast(post.title);
    }
    return builder.build();
  },
  [ROUTE_LOGIN]: ({ state, t }) => {
    const builder = new BreadcrumbsBuilder();
    builder.addLink('/', getForumData(state).label);
    builder.addSeparator();
    builder.addLast(t('breadcrumbs.login'));
    return builder.build();
  },
  [ROUTE_ACCOUNT_SUSPENDED]: ({ state, t }) => {
    const builder = new BreadcrumbsBuilder();
    builder.addLink('/', getForumData(state).label);
    builder.addSeparator();
    builder.addLast(t('breadcrumbs.account-suspended'));
    return builder.build();
  },
  [ROUTE_SEARCH]: ({ state, t, santaNavigationToHomeIssueWorkaroundHack }) => {
    const builder = new BreadcrumbsBuilder();
    builder.addLink('/', getForumTitle(state), santaNavigationToHomeIssueWorkaroundHack);
    builder.addSeparator();
    builder.addLast(t('breadcrumbs.search'));
    return builder.build();
  },
  [ROUTE_COMMENT_DEEP_LINK]: ({ state, santaNavigationToHomeIssueWorkaroundHack }) => {
    const builder = new BreadcrumbsBuilder();
    builder.addLink('/', getForumTitle(state), santaNavigationToHomeIssueWorkaroundHack);
    builder.addSeparator();
    builder.addLast('...');
    return builder.build();
  },
  [ROUTE_HOME]: ({ state }) => {
    const builder = new BreadcrumbsBuilder();
    const categories = getAllCategories(state);
    const label = categories.length === 1 ? categories[0].label : getForumData(state).label;
    builder.addLast(label);
    return builder.build();
  },
  [ROUTE_CATEGORY]: ({ state, params, santaNavigationToHomeIssueWorkaroundHack }) => {
    const builder = buildCategoryBreadcrumbs({
      state,
      params,
      categoryIsLink: false,
      santaNavigationToHomeIssueWorkaroundHack,
    });
    return builder.build();
  },
  [ROUTE_CREATE_POST]: ({ state, t, santaNavigationToHomeIssueWorkaroundHack }) => {
    const builder = new BreadcrumbsBuilder();
    builder.addLink('/', getForumData(state).label, santaNavigationToHomeIssueWorkaroundHack);
    builder.addSeparator();
    builder.addLast(t('breadcrumbs.create-post'));
    return builder.build();
  },
  [ROUTE_CREATE_QUESTION]: ({ state, t, santaNavigationToHomeIssueWorkaroundHack }) => {
    const builder = new BreadcrumbsBuilder();
    builder.addLink('/', getForumData(state).label, santaNavigationToHomeIssueWorkaroundHack);
    builder.addSeparator();
    builder.addLast(t('breadcrumbs.create-question'));
    return builder.build();
  },
  [ROUTE_CATEGORY_CREATE_POST]: ({
    state,
    params,
    t,
    santaNavigationToHomeIssueWorkaroundHack,
  }) => {
    const builder = buildCategoryBreadcrumbs({
      state,
      params,
      santaNavigationToHomeIssueWorkaroundHack,
    });
    builder.addSeparator();
    builder.addLast(t('breadcrumbs.create-post'));
    return builder.build();
  },
  [ROUTE_CATEGORY_CREATE_QUESTION]: ({
    state,
    params,
    t,
    santaNavigationToHomeIssueWorkaroundHack,
  }) => {
    const builder = buildCategoryBreadcrumbs({
      state,
      params,
      santaNavigationToHomeIssueWorkaroundHack,
    });
    builder.addSeparator();
    builder.addLast(t('breadcrumbs.create-question'));
    return builder.build();
  },
  [ROUTE_POST_EDIT]: ({ state, params, t, santaNavigationToHomeIssueWorkaroundHack }) => {
    const builder = buildCategoryBreadcrumbs({
      state,
      params,
      santaNavigationToHomeIssueWorkaroundHack,
    });
    const post = getPostBySlug(state, params.postSlug);
    if (post) {
      builder.addSeparator();
      builder.addLink(`/${params.categorySlug}/${params.postSlug}`, post.title);
      builder.addSeparator();
      builder.addLast(t('breadcrumbs.edit-post'));
    }
    return builder.build();
  },
};

const Breadcrumbs = ({
  contentFontClassName,
  state,
  route,
  params,
  santaNavigationToHomeIssueWorkaroundHack,
  isPreview,
  isEditor,
  t,
}) => {
  const breadcrumbFactory = ROUTE_BREADCRUMBS_FACTORY_MAP[route];
  if (!breadcrumbFactory) {
    console.error(`Missing breadcrumbs factory for route ${route}`);
    return null;
  }
  const breadcrumbs = breadcrumbFactory({
    state,
    route,
    params,
    santaNavigationToHomeIssueWorkaroundHack:
      isSanta && !isPreview && !isEditor ? santaNavigationToHomeIssueWorkaroundHack : undefined,
    t,
  });
  return (
    <nav
      aria-label={t('breadcrumbs.breadcrumbs')}
      className={classNames(styles.breadcrumbs, contentFontClassName, 'breadcrumbs-text-color')}
    >
      <ol className={styles.listContainer}>{breadcrumbs}</ol>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  contentFontClassName: PropTypes.string,
  state: PropTypes.object,
  route: PropTypes.string,
  params: PropTypes.object,
  isPreview: PropTypes.bool,
  isEditor: PropTypes.bool,
  santaNavigationToHomeIssueWorkaroundHack: PropTypes.func,
  t: PropTypes.func,
};

const mapRuntimeToProps = state => ({
  state,
  route: getRoute(state),
  isPreview: isPreview(state),
  isEditor: isEditor(state),
  params: getRouteParams(state),
  santaNavigationToHomeIssueWorkaroundHack: () =>
    new Promise(() => {
      location.href = getSectionUrl(state);
    }),
});

export default flowRight(connect(mapRuntimeToProps), withFontClassName, withTranslate)(Breadcrumbs);
