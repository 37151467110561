import PropTypes from 'prop-types';
import React from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import AvatarImage from '../avatar-image';
import CurrentUserAvatarImage from '../../containers/current-user-avatar-image';
import { CARD_SIZE_SMALL, CARD_SIZE_MEDIUM } from '../../constants/card-sizes';
import { EditIcon } from '../icons/edit-icon';
import withAuth from '../../hoc/with-auth';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import styles from './create-post-card.scss';
import { handleEnterAndSpaceKeyUp } from '../../services/accessibility';
import { connect } from '../../../common/components/runtime-context';
import { getCardSize } from '../../selectors/app-settings-selectors';

const TYPES = {
  [CARD_SIZE_SMALL]: 'small',
  [CARD_SIZE_MEDIUM]: 'medium',
};

const CreatePostCard = ({ cardSize, isAuthenticated, onClick, contentFontClassName, t }) => {
  const type = TYPES[cardSize];
  const className = classNames(
    styles.button,
    contentFontClassName,
    'button-background-color',
    'button-primary-text-color',
    'create-post',
    { [styles[type]]: type },
  );

  return (
    <div
      className={className}
      onClick={onClick}
      tabIndex="0"
      onKeyUp={handleEnterAndSpaceKeyUp(onClick)}
      data-hook="create-post"
      role="button"
      aria-labelledby="create-post-label"
    >
      {isAuthenticated ? <CurrentUserAvatarImage /> : <AvatarImage />}
      <span id="create-post-label" className={styles.text}>
        {t('create-post-card.create-post')}
      </span>
      <EditIcon className={classNames(styles.icon, 'button-primary-icon-fill')} />
    </div>
  );
};

CreatePostCard.propTypes = {
  t: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  cardSize: PropTypes.number,
  onClick: PropTypes.func,
  contentFontClassName: PropTypes.string.isRequired,
};

CreatePostCard.defaultProps = {
  cardSize: CARD_SIZE_SMALL,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  cardSize: getCardSize(state, host.style),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withAuth,
  withFontClassName,
  withTranslate,
)(CreatePostCard);
