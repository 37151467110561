import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import ActionButton from '../action-button';
import MoreButton from '../more-button';
import { ArrowDown } from '../icons/arrow-down';
import { getAllCategories } from '../../selectors/categories-selectors';
import withTranslate from '../../hoc/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './category-select.scss';
import { isPrivate } from '../../../common/services/category-privacy-utils';
import { LockEmptyIcon } from '../icons/lock-empty-icon';
import { CurrencySignIcon } from '../icons/currency-sign-icon';
import { hasCategoryPaidPlans } from '../../selectors/paid-plans-selectors';

const ALL_POSTS_CATEGORY_SLUG = '';

const Pipe = <span className={classNames(styles.pipe, 'forum-card-border-color')} />;

class CategorySelect extends Component {
  state = {
    isActionsVisible: false,
  };

  handleCategoryChange = categorySlug => this.props.redirectToCategory(categorySlug);

  handlePostFetch = category => {
    this.props.resetPostTypeFilter();
    this.props.fetchCategoryPosts({ categoryId: category._id, page: 1 });
  };

  onActionsShow = () => this.setState({ isActionsVisible: true });
  onActionsHide = () => this.setState({ isActionsVisible: false });

  renderArrow = () => {
    const className = this.state.isActionsVisible ? styles.arrowUp : undefined;
    return (
      <span className={classNames(styles.arrow, className)}>
        <ArrowDown />
      </span>
    );
  };

  render() {
    const { t, categories, categorySlug, contentFontClassName, state } = this.props;
    const allPostsCategory = {
      label: t('category-select.all-posts'),
      value: ALL_POSTS_CATEGORY_SLUG,
      isIndented: false,
      _id: null,
    };
    const categoryOptions = categories.map(category => ({
      label: category.label,
      value: category.slug,
      isIndented: !!category.parentId,
      isPrivate: isPrivate(category),
      iconComponent: hasCategoryPaidPlans(state, category.groups)
        ? CurrencySignIcon
        : LockEmptyIcon,
      _id: category._id,
    }));
    const options = [allPostsCategory, ...categoryOptions];
    const selectedCategory = options.find(c => c.value === categorySlug);

    const current = (
      <div className={styles.selectedCategory}>
        {selectedCategory ? selectedCategory.label : ''}
        {this.renderArrow()}
      </div>
    );
    const className = classNames('breadcrumbs-text-color', contentFontClassName, styles.moreButton);

    return (
      <div data-hook="category-select">
        <MoreButton
          icon={current}
          position={'right'}
          className={className}
          iconClassName={classNames(
            'breadcrumbs-icon-fill',
            'button-hover-color',
            'button-hover-fill',
          )}
          onHide={this.onActionsHide}
          onShow={this.onActionsShow}
        >
          {options.map(o => {
            const Icon = o.iconComponent;
            const classNameActionButton =
              o.value === ALL_POSTS_CATEGORY_SLUG
                ? classNames(styles.allPostsActionButton, 'forum-card-border-color')
                : undefined;
            return (
              <ActionButton
                key={o.value}
                onClick={() => this.handleCategoryChange(o.value)}
                onMouseDown={() => this.handlePostFetch(o)}
                className={classNameActionButton}
                dataHook={`category-select-action__${o.value}`}
              >
                <div className={styles.actionTextWrapper}>
                  <div className={styles.actionText}>
                    {o.isIndented && Pipe}
                    {o.label}
                  </div>
                  {o.isPrivate && <Icon className="forum-icon-fill" />}
                </div>
              </ActionButton>
            );
          })}
        </MoreButton>
      </div>
    );
  }
}

CategorySelect.propTypes = {
  t: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  categorySlug: PropTypes.string.isRequired,
  redirectToCategory: PropTypes.func,
  fetchCategoryPosts: PropTypes.func,
  resetPostTypeFilter: PropTypes.func,
  contentFontClassName: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  state,
  categories: getAllCategories(state),
  categorySlug: ownProps.params.categorySlug || ALL_POSTS_CATEGORY_SLUG,
  redirectToCategory: categorySlug => actions.navigateWithinForum(`/${categorySlug}`),
  fetchCategoryPosts: actions.fetchCategoryPosts,
  resetPostTypeFilter: actions.resetPostTypeFilter,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withFontClassName,
)(CategorySelect);
