import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withTranslate from '../../hoc/with-translate';
import styles from './top-comment.scss';
import IconTooltip from '../icon-tooltip';
import { flowRight } from 'lodash';
import { SolvedIcon } from '../icons/solved-icon';

const TopCommentDesktop = ({ showIconOnly, t }) => (
  <div className={styles.topComment} data-hook="top-comment">
    <IconTooltip text={t('comment.solved.best')}>
      <SolvedIcon className="button-fill" />
    </IconTooltip>
    {!showIconOnly && (
      <span className={classNames(styles.topCommentLabel, 'button-color')}>
        {t('comment.solved.best')}
      </span>
    )}
  </div>
);

TopCommentDesktop.propTypes = {
  t: PropTypes.func,
  showIconOnly: PropTypes.bool,
};

export default flowRight(withTranslate)(TopCommentDesktop);
