import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { LIKE_POST } from '../../constants/interactions';
import LikeButton from '../like-button';
import ProtectedButton from '../../containers/protected-button';
import PostListItem from '../post-list-item';
import { HorizontalSeparator } from '../separator';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import { DISCUSSION } from '../../constants/post-types';
import withTranslate from '../../hoc/with-translate';
import styles from './post-footer.scss';

const PostFooter = ({
  post,
  forPublicUser,
  onLikeClick,
  Link,
  postLink,
  t,
  contentFontClassName,
  isViewsCountEnabled,
  type,
  interactionStarted,
  showSeparator,
  showViewCount,
  isQACategoriesEnabled,
  userEventsClickLike,
}) => {
  const handleLikeClick = e => {
    userEventsClickLike({
      type: 'post',
      _id: post._id,
      isLiked: post.isLiked,
    });
    e.preventDefault();
    interactionStarted(LIKE_POST);
    onLikeClick(`${post._id}`);
  };
  const likeCount = post.likeCount || 0;
  const shouldShowSeparator = type === PostListItem.SEARCH || showSeparator;
  const commentCount = isQACategoriesEnabled
    ? post.totalComments - post.totalReplies
    : post.totalComments;
  const isDiscussion = post.postType === DISCUSSION;

  return (
    <div
      className={classNames(
        styles.footer,
        styles[type],
        isQACategoriesEnabled && styles.QACategories,
      )}
    >
      {shouldShowSeparator && <HorizontalSeparator className={styles.searchSeparator} />}
      <div className={styles.footerContent}>
        <div className={styles.group}>
          <div className={styles.item}>
            <Link
              className={classNames(
                styles.commentCount,
                'forum-text-color',
                'forum-link-hover-color',
              )}
              to={`${postLink}`}
              data-hook="post-list-item__comment-count"
            >
              {t(isDiscussion ? 'post-list-item.total-comments' : 'post-list-item.total-answers', {
                count: commentCount || 0,
              })}
            </Link>
          </div>
          {isQACategoriesEnabled && (
            <div className={styles.item}>
              <Link
                className={classNames(
                  styles.replyCount,
                  'forum-text-color',
                  'forum-link-hover-color',
                )}
                to={`${postLink}`}
                data-hook="post-list-item__reply-count"
              >
                {t('post-list-item.total-replies', { count: post.totalReplies || 0 })}
              </Link>
            </div>
          )}
          {isViewsCountEnabled && (
            <div
              className={classNames(styles.viewCount, styles.item, 'post-list-item__view-count')}
              data-hook="post-list-item__view-count"
            >
              {showViewCount ? t('post-list-item.view-count', { count: post.viewCount || 0 }) : ''}
            </div>
          )}
        </div>
        <div className={styles.likeContainer}>
          <span
            className={classNames(styles.likeCount, 'forum-text-color')}
            data-hook="post-list-item__like-count"
          >
            {t('post-list-item.like-count', { count: likeCount })}
          </span>
          <ProtectedButton
            className={classNames(styles.likeButton, contentFontClassName)}
            onClick={forPublicUser(handleLikeClick, { preventDefault: true })}
            data-hook="post-list-item__like-button"
            actionDetails={{ action: LIKE_POST, args: [post._id] }}
          >
            <LikeButton
              isLiked={post.isLiked}
              likeCount={likeCount}
              isDisabled={post.isLikeInProgress}
            />
          </ProtectedButton>
        </div>
      </div>
    </div>
  );
};

PostFooter.MEMBERS_AREA = 'membersArea';
PostFooter.FOOTER_POST = 'footerPost';

PostFooter.propTypes = {
  forPublicUser: PropTypes.func.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  Link: PropTypes.func.isRequired,
  postLink: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  contentFontClassName: PropTypes.string,
  type: PropTypes.string,
  isViewsCountEnabled: PropTypes.bool,
  interactionStarted: PropTypes.func,
  showSeparator: PropTypes.bool,
  showViewCount: PropTypes.bool,
  isQACategoriesExperimentEnabled: PropTypes.bool,
  userEventsClickLike: PropTypes.func.isRequired,
};

PostFooter.defaultProps = {
  showSeparator: false,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  interactionStarted: actions.interactionStarted,
  showViewCount: !isCacheableRendering(state),
  userEventsClickLike: actions.userEventsClickLike,
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(PostFooter);
