import PropTypes from 'prop-types';
import React from 'react';

import { BulletSeparator } from '../separator';

import withTranslate from '../../hoc/with-translate';
import styles from './top-comment.scss';
import { flowRight } from 'lodash';
import { SolvedIcon } from '../icons/solved-icon';

const TopCommentDesktop = ({ t }) => (
  <div className={styles.topComment} data-hook="top-comment">
    <BulletSeparator spacing={1} />
    <span className={styles.topCommentLabel}>{t('comment.solved.best')}</span>

    <SolvedIcon className="icon-secondary-fill" isColored />
  </div>
);

TopCommentDesktop.propTypes = {
  t: PropTypes.func,
};

export default flowRight(withTranslate)(TopCommentDesktop);
