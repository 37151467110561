import PropTypes from 'prop-types';
import React from 'react';

import styles from './top-comment.scss';
import { SolvedIcon } from '../icons/solved-icon';

const TopCommentMobile = () => (
  <div className={styles.topComment} data-hook="top-comment">
    <SolvedIcon className="icon-secondary-fill" isColored />
  </div>
);

TopCommentMobile.propTypes = {
  topCommentColor: PropTypes.string,
  t: PropTypes.func,
};

export default TopCommentMobile;
