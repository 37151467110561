import { flowRight, get } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { scrollToPostCommentForm } from '../../services/scroll-to-post-comment-form';
import ProtectedButton from '../../containers/protected-button';
import withAuth from '../../hoc/with-auth';
import withTranslate from '../../hoc/with-translate';
import styles from './post-page-header-actions.scss';
import withFontClassName from '../../hoc/with-font-class-name';
import { FOLLOW_POST } from '../../constants/interactions';

class ActionButtonRaw extends Component {
  render() {
    const { onClick, text, disabled, contentFontClassName, actionDetails } = this.props;
    return (
      <ProtectedButton
        className={classNames(styles.button, contentFontClassName, 'post-header-text-color', {
          'button-hover-color': !disabled,
          [styles.buttonDisabled]: disabled,
        })}
        onClick={onClick}
        actionDetails={actionDetails}
      >
        {text}
      </ProtectedButton>
    );
  }
}

ActionButtonRaw.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  contentFontClassName: PropTypes.string,
};

const ActionButton = withFontClassName(ActionButtonRaw);

class PostPageHeaderActions extends Component {
  handleSubscription = () => {
    const {
      post: { _id, isSubscribed },
      subscribe,
      unsubscribe,
    } = this.props;

    if (isSubscribed) {
      unsubscribe(_id);
    } else {
      subscribe(_id);
    }
  };
  render() {
    const { forPublicUser, post, t } = this.props;
    const isCommentsDisabled = get(post, 'isCommentsDisabled', false);
    return (
      <div className={styles.container}>
        <ActionButton
          onClick={forPublicUser(this.handleSubscription)}
          text={post.isSubscribed ? t('post-actions.unfollow') : t('post-actions.follow')}
          actionDetails={{ action: FOLLOW_POST, args: [post._id] }}
        />

        <ActionButton
          onClick={forPublicUser(scrollToPostCommentForm)}
          text={t('comment.reply')}
          disabled={isCommentsDisabled}
        />
      </div>
    );
  }
}

PostPageHeaderActions.propTypes = {
  t: PropTypes.func,
  forPublicUser: PropTypes.func,
  unsubscribe: PropTypes.func,
  subscribe: PropTypes.func,
  post: PropTypes.object,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  subscribe: actions.subscribeToPost,
  unsubscribe: actions.unsubscribeFromPost,
});

export default flowRight(
  withAuth,
  withTranslate,
  connect(mapRuntimeToProps),
)(PostPageHeaderActions);
