import React from 'react';
import { I18nextProvider } from 'react-i18next';
import DirectionProvider, { DIRECTIONS } from 'react-with-direction/dist/DirectionProvider';
import {
  ImgProvider,
  RENDER_IMAGE,
  RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY,
} from '@wix/communities-image-lib';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import RuntimeContext from '../../../common/components/runtime-context';
import ResponsiveListener from '../../components/responsive-listener';
import Router from '../router';
import { PermissionsContextProvider } from '../../../common/components/permissions-context';
import { initI18n } from '../../services/i18n-provider';
import { getCategory } from '../../../common/selectors/categories-selectors';
import {
  getLanguage,
  isDebug,
  isProduction,
  isSeo,
  getIsMobile,
} from '../../../common/store/basic-params/basic-params-selectors';
import { isWebpSupported } from '../../../common/services/is-webp-supported';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { FastFormProvider } from '../../../common/components/fast-form';
import { usePromisifiedAction } from '../../../common/actions-promisifier/use-promisified-action';
import { getFastFormSubmitAction } from '../../../common/components/fast-form/store/fast-form-selectors';
import { withPromisifiedOpenModal } from '../../../common/modals/framework/store/modal-actions';
import { initBrowserRefreshPrompt } from '../../../common/services/browser-refresh-prompt-service';
import '../../components/rce/rce-styles.global.scss';
import {
  getPinterestMain,
  loadPinterestPin,
  pinterestListen,
  pinterestPinId,
} from '../../services/pinterest';
import { logPageDef } from '../../services/page-def-data-logger';
import GlobalLoader from '../global-loader';

const permissionsResourceResolver = state => resource => {
  if (resource.categoryId) {
    resource = getCategory(state, resource.categoryId);
  }
  return resource;
};

class AppRoot extends React.Component {
  constructor(props) {
    super(props);
    if (typeof window !== 'undefined') {
      if (!window.PinUtils && !document.getElementById(pinterestPinId)) {
        loadPinterestPin(getLanguage(props.state));
      } else {
        pinterestListen(getPinterestMain());
      }
    }
    initBrowserRefreshPrompt();
  }

  componentWillUnmount() {
    pinterestListen(getPinterestMain(), true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSSR !== this.props.isSSR && this.workerInitialized) {
      this.workerInitialized();
    }
    if (this.props.state.reloadPage) {
      window.location.reload();
    }
  }

  getI18nConfig = (language, translations) => {
    if (this.lastLanguage !== language) {
      this.i18nConfig = initI18n(language, translations);
      this.lastLanguage = language;
    }
    return this.i18nConfig;
  };

  getWorkerInitializeListener = () => {
    this.whenWorkerInitialized =
      this.whenWorkerInitialized ||
      new Promise(res => {
        if (!this.props.isSSR) {
          res();
          this.workerInitialized = () => {};
        } else {
          this.workerInitialized = res;
        }
      });
    return this.whenWorkerInitialized;
  };

  render() {
    const { state, actions, actionsPromisified, host, isRTL } = this.props;
    const i18n = this.getI18nConfig(getLanguage(state), getTranslations(state));

    if (isDebug(state) || !isProduction(state)) {
      console.log('AppRoot', this.props);
    }

    if (isDebug(state)) {
      logPageDef(state);
    }

    const connectedActionsPromisified = Object.keys(actionsPromisified).reduce(
      (wrapped, actionName) => {
        wrapped[actionName] = usePromisifiedAction(actionsPromisified[actionName], state);
        return wrapped;
      },
      {},
    );

    return (
      <RuntimeContext.Provider
        value={{
          state,
          actions: {
            ...actions,
            ...connectedActionsPromisified,
            openModal: withPromisifiedOpenModal(actions.openModal),
          },
          host,
          whenWorkerInitialized: this.getWorkerInitializeListener(),
        }}
      >
        <DirectionProvider direction={isRTL ? DIRECTIONS.RTL : DIRECTIONS.LTR}>
          <TPAComponentsProvider value={{ mobile: getIsMobile(state), rtl: isRTL }}>
            <ResponsiveListener>
              <PermissionsContextProvider
                state={state}
                resourceResolver={permissionsResourceResolver}
              >
                <I18nextProvider i18n={i18n}>
                  <ImgProvider
                    renderMode={
                      isSeo(state) ? RENDER_IMAGE : RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY
                    }
                    format={isWebpSupported() ? 'webp' : 'png'}
                  >
                    <FastFormProvider
                      submitAction={getFastFormSubmitAction(state)}
                      // onSubmit={actions.fastFormSubmitCallback} // unused currently, when used need to tranform contentState
                    >
                      <GlobalLoader>
                        <Router />
                      </GlobalLoader>
                    </FastFormProvider>
                  </ImgProvider>
                </I18nextProvider>
              </PermissionsContextProvider>
            </ResponsiveListener>
          </TPAComponentsProvider>
        </DirectionProvider>
      </RuntimeContext.Provider>
    );
  }
}

export default AppRoot;
