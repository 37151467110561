import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './loader.scss';

const Loader = ({ bubbleClassName, containerProps, small }) => (
  <div className={styles.container} {...containerProps}>
    <span className={classNames(styles.dot, { [styles.small]: small }, bubbleClassName)} />
    <span className={classNames(styles.dot, { [styles.small]: small }, bubbleClassName)} />
    <span className={classNames(styles.dot, { [styles.small]: small }, bubbleClassName)} />
  </div>
);

Loader.propTypes = {
  bubbleClassName: PropTypes.string,
  containerProps: PropTypes.object,
  small: PropTypes.bool,
};

Loader.defaultProps = {
  bubbleClassName: 'forum-text-background-color',
  small: false,
};

export default Loader;
