import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProtectedButton from '../../containers/protected-button';
import { connect } from '../../../common/components/runtime-context';
import { ReplyIcon } from '../icons/reply-icon';
import withTranslate from '../../hoc/with-translate';
import withAuth from '../../hoc/with-auth';
import styles from './reply-button.scss';
import withFontClassName from '../../hoc/with-font-class-name';
import { getIsRegistrationWithApproval } from '../../selectors/forum-data-selectors';

class ReplyButton extends Component {
  render() {
    const {
      t,
      onClick,
      forPublicUser,
      dataHook,
      type,
      contentFontClassName,
      userName,
      isRegistrationWithApproval,
    } = this.props;
    const Component = isRegistrationWithApproval ? ProtectedButton : 'button';

    return (
      <Component
        className={classNames(styles.replyButton, contentFontClassName, 'button-hover-color')}
        aria-label={`${t('reply-button.reply-to', { userName })}`}
        onClick={forPublicUser(onClick)}
      >
        <ReplyIcon className={styles.replyIcon} />
        <span data-hook={dataHook} className={classNames(styles.reply, dataHook)}>
          {t(`comment.${type}`)}
        </span>
      </Component>
    );
  }
}

ReplyButton.propTypes = {
  dataHook: PropTypes.string,
  mainColor: PropTypes.string,
  t: PropTypes.func,
  forPublicUser: PropTypes.func,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['reply', 'comment', 'answer']).isRequired,
  contentFontClassName: PropTypes.string,
  userName: PropTypes.string,
  isRegistrationWithApproval: PropTypes.bool,
};

const mapRuntimeToProps = state => ({
  isRegistrationWithApproval: getIsRegistrationWithApproval(state),
});

export default flowRight(
  withFontClassName,
  withTranslate,
  withAuth,
  connect(mapRuntimeToProps),
)(ReplyButton);
