import React from 'react';

export const MoveIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.66"
    height="16"
    viewBox="0 0 17.66 16"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path
      d="M1003,545.657l-1.46-1.456,3.4-3.2H995.5a0.5,0.5,0,0,1-.5-0.5v-1a0.5,0.5,0,0,1,.5-0.5h9.44l-3.4-3.2,1.46-1.456,5.66,5.657ZM998,534h-5v12h5a1,1,0,0,1,0,2h-6a1,1,0,0,1-1-1V533a1,1,0,0,1,1-1h6A1,1,0,0,1,998,534Z"
      transform="translate(-991 -532)"
    />
  </svg>
);
