import { trim, get } from 'lodash';
import hasActiveUploads from './has-active-uploads';
import { createLSFormStore } from '../../common/components/fast-form/external-ls-store';

const postFormSettings = {
  formName: 'post',
  resetOnUnmount: true,
  externalStore: (options, compProps) => {
    if (!compProps.isLocalDraftEnabled) {
      return null;
    }

    const isEdit = get(compProps, 'formInitialValues.createdDate') !== undefined;

    return isEdit
      ? null
      : createLSFormStore(
          `${get(compProps, 'formInitialValues.categoryId', get(compProps, 'categoryId'))}-${get(
            compProps,
            'formInitialValues.postType',
            get(compProps, 'postType'),
          )}-post`,
        );
  },
  validate: values => {
    const errors = {};
    const { title, content } = values;

    if (!trim(title)) {
      errors.title = 'required';
    }

    if (content && hasActiveUploads(content)) {
      errors.content = 'uploading';
    }

    return errors;
  },
};

export default postFormSettings;
