import { flowRight } from 'lodash';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COVER_TYPE_IMAGE } from '../../constants/cover-types';
import MoreButton from '../../components/more-button';
import CategoryActions from '../category-actions';
import Link from '../../components/link/internal-link';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import styles from './subcategory-list-item-desktop.scss';
import FollowButton from '../../components/follow-button/follow-button';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import withPermissions from '../../hoc/with-permissions';
import { ImgBackground } from '@wix/communities-image-lib';
import { isPrivate } from '../../../common/services/category-privacy-utils';
import { LockEmptyIcon } from '../../components/icons/lock-empty-icon';
import { CurrencySignIcon } from '../../components/icons/currency-sign-icon';
import { hasCategoryPaidPlans } from '../../selectors/paid-plans-selectors';

export class SubcategoryListItemDesktop extends Component {
  hasImage = () => this.props.category.coverType === COVER_TYPE_IMAGE;

  renderImageIfNeeded = () => {
    const image = this.hasImage() && (
      <ImgBackground
        className={styles.image}
        src={this.props.category.cover}
        width={200}
        height={120}
      />
    );
    return image && this.linkToPosts(image);
  };

  renderContentBlock = () => {
    const { titleFontClassName, category, hasCategoryPP, t } = this.props;
    let categoryBadge = null;
    if (isPrivate(category)) {
      const Icon = hasCategoryPP ? CurrencySignIcon : LockEmptyIcon;
      categoryBadge = (
        <span className={styles.categoryBadgeWrapper}>
          <Icon className="forum-icon-fill" />
        </span>
      );
    }
    const titleText = (
      <h3
        className={classNames(styles.title, titleFontClassName)}
        data-hook="subcategories-list-item__title"
      >
        <span>{category.label}</span> {categoryBadge}
      </h3>
    );
    return (
      <div className={styles.contentBlock}>
        {this.linkToPosts(titleText, 'subcategories-list-item__link')}
        <span
          style={{ width: this.hasImage() ? '315px' : '443px' }}
          className={styles.description}
          data-hook="subcategories-list-item__description"
        >
          {category.description}
        </span>
      </div>
    );
  };

  renderNumbersElement = (number, text, dataHook) => (
    <div className={styles.numberElement} data-hook={dataHook}>
      <span className={classNames(styles.numberElementHeader, this.props.titleFontClassName)}>
        {number}
      </span>
      <span>{text}</span>
    </div>
  );

  handlePostFetch = () => {
    const { category, fetchCategoryPosts, resetPostTypeFilter } = this.props;
    resetPostTypeFilter();
    fetchCategoryPosts({ categoryId: category._id, page: 1 });
  };

  linkToPosts = (children, dataHook) => {
    const { category } = this.props;
    return (
      <Link
        className={classNames('forum-text-color', 'forum-link-hover-color')}
        to={`/${category.slug}`}
        onMouseDown={this.handlePostFetch}
        data-hook={dataHook}
      >
        {children}
      </Link>
    );
  };

  render() {
    const {
      category,
      contentFontClassName,
      isViewsCountEnabled,
      showViewCount,
      t,
      can,
    } = this.props;
    const shouldShowMoreButton = can('edit', 'category', category);
    const moreButton = (
      <MoreButton data-hook="subcategories-list-item__category-actions">
        <CategoryActions hideFollowButton category={category} />
      </MoreButton>
    );
    const followButton = (
      <FollowButton
        category={category}
        className={shouldShowMoreButton ? styles.followButton : ''}
        data-hook="subcategories-list-item__follow-button"
      >
        {t(category.isSubscribed ? 'category-actions.unfollow' : 'category-actions.follow')}
      </FollowButton>
    );
    const categoryActions = (
      <div className={styles.actionsContainer}>
        {followButton}
        {shouldShowMoreButton && moreButton}
      </div>
    );
    const categoryContent = (
      <div className={styles.main}>
        <div className={classNames(styles.contentPreview, 'forum-text-color')}>
          {this.renderImageIfNeeded()}
          {this.renderContentBlock()}
        </div>
        <div
          className={classNames(styles.numbers, {
            [styles.viewsHidden]: !isViewsCountEnabled,
          })}
        >
          {isViewsCountEnabled &&
            this.renderNumbersElement(
              showViewCount ? category.totalViews : <span>&nbsp;</span>,
              t('subcategory-list-item.views'),
              'subcategories-list-item__total-views',
            )}
          {this.renderNumbersElement(
            category.totalPosts,
            t('subcategory-list-item.posts', 'subcategories-list-item__total-posts'),
            'subcategories-list-item__total-posts',
          )}
        </div>
      </div>
    );

    return (
      <div
        className={classNames(styles.container, contentFontClassName)}
        data-hook="subcategories-list-item"
      >
        {categoryContent}

        {categoryActions}
      </div>
    );
  }
}

SubcategoryListItemDesktop.propTypes = {
  t: PropTypes.func,
  category: PropTypes.object,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  isViewsCountEnabled: PropTypes.bool,
  showViewCount: PropTypes.bool,
  hasCategoryPP: PropTypes.bool,
  fetchCategoryPosts: PropTypes.func,
  resetPostTypeFilter: PropTypes.func,
  can: PropTypes.func,
};

const mapRuntimeToProps = (state, { category }, actions) => ({
  fetchCategoryPosts: actions.fetchCategoryPosts,
  resetPostTypeFilter: actions.resetPostTypeFilter,
  showViewCount: !isCacheableRendering(state),
  hasCategoryPP: hasCategoryPaidPlans(state, category.groups),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withFontClassName,
  withPermissions,
)(SubcategoryListItemDesktop);
