import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import PostListMobileClassic from '../post-list-mobile-classic';
import PostListMobileClassicYak from '../post-list-mobile-classic-yak';
import withTranslate from '../../hoc/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import { isQACategoriesExperimentEnabled } from '../../selectors/experiments-selectors';
import styles from './footer-posts-mobile.scss';
import { TYPE_NEW_POSTS } from './types';

const FooterPostsMobile = ({
  t,
  titleFontClassName,
  posts,
  onLikeClick,
  isQACategoriesEnabled,
  type,
}) => {
  return (
    <div className={classNames(styles.container)} data-hook="footer-posts">
      <div
        className={classNames(
          titleFontClassName,
          'default-desktop-header-text-color',
          'forum-card-background-color',
          styles.title,
        )}
      >
        {t(type === TYPE_NEW_POSTS ? 'footer-posts.title' : 'footer-posts.related-posts')}
      </div>
      {isQACategoriesEnabled ? (
        <PostListMobileClassicYak
          posts={posts}
          onLikeClick={onLikeClick}
          showCreatePostAction={false}
          showCategoryLink={false}
          showLockIcon={false}
          showMoreButton={false}
          showPinIcon={false}
          showTopComment={false}
          showViewCount={false}
          fullWidthSeparator
        />
      ) : (
        <PostListMobileClassic
          posts={posts}
          onLikeClick={onLikeClick}
          showCreatePostAction={false}
          showCategoryLink={false}
          showLockIcon={false}
          showMoreButton={false}
          showPinIcon={false}
          showTopComment={false}
          showViewCount={false}
          fullWidthSeparator
        />
      )}
    </div>
  );
};

FooterPostsMobile.propTypes = {
  t: PropTypes.func,
  titleFontClassName: PropTypes.string,
  posts: PropTypes.array,
  onLikeClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  onLikeClick: actions.incrementPostLikeCount,
  isQACategoriesEnabled: isQACategoriesExperimentEnabled(state),
});

export default flowRight(
  withTranslate,
  withFontClassName,
  connect(mapRuntimeToProps),
)(FooterPostsMobile);
