import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Interpolate } from 'react-i18next';
import ProtectedButton from '../../containers/protected-button';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import styles from './login-to-comment-mobile.scss';

const LoginToCommentMobile = ({ contentFontClassName, buttonClicked, t }) => (
  <div className={classNames(styles.container, contentFontClassName)}>
    <ProtectedButton
      tagName="div"
      className={classNames('forum-text-color')}
      beforeRequestLogin={() => buttonClicked({ name: 'log_in_to_comment', type: 'login' })}
    >
      <Interpolate
        i18nKey="login-to-comment.login-to-join"
        login={<span className="button-color">{t('login-to-comment.login')}</span>}
      />
    </ProtectedButton>
  </div>
);

LoginToCommentMobile.propTypes = {
  contentFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  buttonClicked: PropTypes.func,
};

export default withTranslate(withFontClassName(LoginToCommentMobile));
