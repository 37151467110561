let convertFromRawLoaded;
let convertToRawLoaded;

export const convertFromRaw = state => {
  if (convertFromRawLoaded) {
    return convertFromRawLoaded(state);
  } else {
    return import('wix-rich-content-editor/dist/lib/editorStateConversion.cjs').then(module => {
      convertFromRawLoaded = module.convertFromRaw;
      convertToRawLoaded = module.convertToRaw;

      return convertFromRawLoaded(state);
    });
  }
};
export const convertToRaw = state => {
  if (convertToRawLoaded) {
    return convertToRawLoaded(state);
  } else {
    return import('wix-rich-content-editor/dist/lib/editorStateConversion.cjs').then(module => {
      convertFromRawLoaded = module.convertFromRaw;
      convertToRawLoaded = module.convertToRaw;

      return convertToRawLoaded(state);
    });
  }
};

export const loadEditorStateConversionUtils = () => {
  return import('wix-rich-content-editor/dist/lib/editorStateConversion.cjs').then(module => {
    convertFromRawLoaded = module.convertFromRaw;
    convertToRawLoaded = module.convertToRaw;
  });
};

export const hasLoadedConversionUtils = () =>
  convertFromRawLoaded !== undefined && convertToRawLoaded !== undefined;
