import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import styles from './comment-form-wrapper.scss';

const CommentFormWrapper = ({ children }) => {
  const containerClassName = classNames(styles.container, 'forum-card-background-color');

  return (
    <div className={containerClassName} style={{ borderWidth: 0 }}>
      {children}
    </div>
  );
};

CommentFormWrapper.propTypes = {
  children: PropTypes.node,
};

export default withCardBorderWidth(CommentFormWrapper);
