import { flowRight } from 'lodash';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import { getIsMobile } from '../../../common/store/basic-params/basic-params-selectors';
import AvatarGroup from '../avatar-group';
import withTranslate from '../../hoc/with-translate';
import MoreButton from '../more-button';
import styles from './who-liked-popover.scss';
import WhoLikedList from './who-liked-list';
import { MODAL_TYPE_WHO_LIKED_LIST } from '../modals/who-liked-list-modal/who-liked-list-modal-type';

class WhoLikedPopover extends Component {
  render() {
    const { isMobile, totalItemCount, initialItems, postId, commentId, openModal, t } = this.props;

    return isMobile ? (
      <AvatarGroup
        isMobile={isMobile}
        totalItemCount={totalItemCount}
        items={initialItems}
        onClick={() => openModal(MODAL_TYPE_WHO_LIKED_LIST, { postId, commentId })}
      />
    ) : (
      <MoreButton
        ariaLabel={t('who-liked.aria-label')}
        icon={
          <AvatarGroup totalItemCount={totalItemCount} items={initialItems} isMobile={isMobile} />
        }
        containerClassName={styles.popOverContainer}
        parentRef={this.getParentRef}
        position="right"
        noCloseOnContainerClick
      >
        {<WhoLikedList postId={postId} commentId={commentId} />}
      </MoreButton>
    );
  }
}

WhoLikedPopover.propTypes = {
  isMobile: PropTypes.bool,
  totalItemCount: PropTypes.number,
  initialItems: PropTypes.array,
  postId: PropTypes.array,
  commentId: PropTypes.array,
  t: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isMobile: getIsMobile(state),
  openModal: actions.openModal,
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(WhoLikedPopover);
