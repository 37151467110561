import React from 'react';

export const LinkIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.036"
    height="16"
    viewBox="0 0 16.036 16"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path
      d="M1006.6,859.239l-3,3.006a4.218,4.218,0,0,1-6.671-.857l1.4-1.4a1.1,1.1,0,0,1,.228-0.151,2.088,2.088,0,0,0,.526.9,2.131,2.131,0,0,0,3.009,0l3.01-3.006a2.127,2.127,0,1,0-3.01-3.006l-1.07,1.07a5.336,5.336,0,0,0-2.714-.295l2.284-2.279A4.251,4.251,0,0,1,1006.6,859.239Zm-7.951,4.943-1.071,1.07a2.128,2.128,0,0,1-3.011-3.007l3.011-3.006a2.135,2.135,0,0,1,3.012,0,2.218,2.218,0,0,1,.52.9,1.056,1.056,0,0,0,.23-0.15l1.4-1.4a4.321,4.321,0,0,0-.65-0.858,4.262,4.262,0,0,0-6.019,0l-3.01,3.006a4.254,4.254,0,0,0,6.02,6.013l2.279-2.279A5.33,5.33,0,0,1,998.649,864.182Z"
      transform="translate(-991.844 -852)"
    />
  </svg>
);
