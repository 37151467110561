import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import LoginButton from '../login-button';
import EmptyStates from '../empty-states';
import withTranslate from '../../hoc/with-translate';
import styles from './restriction.scss';
import { isPrivate } from '../../../common/services/category-privacy-utils';

const Restriction = ({ t, category }) => (
  <EmptyStates
    className={classNames(styles.emptyStates, 'restriction')}
    title={t('restriction.title')}
    content={t('restriction.content')}
    type={isPrivate(category) ? 'specific_members_other' : 'members_only'}
  >
    <LoginButton onLoginError={noop} onLogin={noop} />
  </EmptyStates>
);

Restriction.propTypes = {
  t: PropTypes.func,
  category: PropTypes.object.isRequired,
};

export default withTranslate(Restriction);
