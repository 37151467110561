import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { ArrowDownIcon } from '../../components/icons/arrow-down-icon';
import { VerticalSeparator } from '../../components/separator';
import Link from '../../components/link/internal-link';
import { SortingSelectCategoryPageMobile } from '../../components/sorting-select-category';
import FilterAndSortButton from '../../components/filter-and-sort-button';
import { isQACategoriesExperimentEnabled } from '../../selectors/experiments-selectors';
import { getCategoryBySlug, getAllCategories } from '../../selectors/categories-selectors';
import { isMultiplePostTypeCategory } from '../../services/categories-service';
import { getRouteParams } from '../../../common/router/router-selectors';
import { isSeo } from '../../../common/store/basic-params/basic-params-selectors';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import withTranslate from '../../hoc/with-translate';
import withMainPageEnabled from '../../hoc/with-main-page-enabled';
import styles from './categories-link.scss';
import ShadowContainer from '../../components/container/shadow-container';

const CategoriesLink = ({
  categories,
  currentCategory,
  onChange,
  cardBackgroundColor,
  t,
  isMainPageEnabled,
  isSeo,
  isQACategoriesEnabled,
}) => {
  const className = classNames(
    styles.container,
    'default-forum-card-background-color',
    'forum-text-color',
    'categories-button',
  );

  const allPostsCategory = {
    label: t('categories-link.all-posts'),
    slug: '',
    isIndented: false,
  };
  const categoryOptions = categories.map(category => ({
    label: category.label,
    slug: category.slug,
    isIndented: !!category.parentId,
  }));
  const options = [...(!isMainPageEnabled ? [allPostsCategory] : []), ...categoryOptions];

  const current = currentCategory || (!isMainPageEnabled ? allPostsCategory : currentCategory);
  const singleCategory = categories.length === 1;
  return (
    <ShadowContainer>
      <div className={className} style={{ backgroundColor: cardBackgroundColor }}>
        <div className={styles.optionsContainer}>
          {!singleCategory && !isSeo && (
            <div
              className={classNames(
                styles.selectContainer,
                isQACategoriesEnabled && styles.QACategory,
              )}
            >
              <span className={styles.categoryTitle}>{current && current.label}</span>
              <span>
                <ArrowDownIcon className="forum-icon-fill" />
              </span>
              <select
                className={styles.select}
                onChange={e => onChange(e.target.value)}
                value={current && current.slug}
                data-hook="category-select"
              >
                {options.map(category =>
                  category.isIndented ? (
                    <option
                      key={category.slug}
                      value={category.slug}
                      data-hook={`category-select-action__${category.slug}`}
                    >
                      {category.label}
                    </option>
                  ) : (
                    <option
                      key={category.slug}
                      value={category.slug}
                      data-hook={`category-select-action__${category.slug}`}
                    >
                      {category.label}
                    </option>
                  ),
                )}
              </select>
            </div>
          )}
          {!singleCategory && isSeo && (
            <div className={styles.selectContainer}>
              {options.map(category => (
                <Link to={`/${category.slug}`}>{category.label}</Link>
              ))}
            </div>
          )}
          {!singleCategory && <VerticalSeparator className={styles.optionsSeparator} />}
          {!isQACategoriesEnabled && (
            <SortingSelectCategoryPageMobile isFullWidth={singleCategory} />
          )}
          {isQACategoriesEnabled && (
            <React.Fragment>
              <FilterAndSortButton
                shouldShowFilter={
                  isMultiplePostTypeCategory(current) || current === allPostsCategory
                }
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </ShadowContainer>
  );
};

CategoriesLink.propTypes = {
  categories: PropTypes.array,
  currentCategory: PropTypes.object,
  cardBackgroundColor: PropTypes.string,
  onChange: PropTypes.func,
  t: PropTypes.func,
  isMainPageEnabled: PropTypes.bool,
  isSeo: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, { navigateWithinForum }) => {
  const { categorySlug } = getRouteParams(state);
  return {
    categories: getAllCategories(state),
    currentCategory: getCategoryBySlug(state, categorySlug),
    onChange: slug => (slug ? navigateWithinForum(`/${slug}`) : navigateWithinForum(`/`)),
    isSeo: isSeo(state),
    isQACategoriesEnabled: isQACategoriesExperimentEnabled(state),
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withCardBackgroundColor,
  withTranslate,
  withMainPageEnabled,
)(CategoriesLink);
