import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import CreatePostButton from '../create-post-button';
import EmptyStates from '../empty-states';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../hoc/with-translate';
import { isPrivate } from '../../../common/services/category-privacy-utils';
import styles from './no-posts.scss';
import { MODAL_TYPE_CATEGORIES_MANAGER } from '../modals/categories-manager/categories-manager-modal-type';
import { MANAGE_MEMBERS } from '../../actions/categories-manager-actions';

class NoPosts extends React.Component {
  componentDidMount() {
    const { category, fetchMembersGroupsList } = this.props;
    if (isPrivate(category)) {
      fetchMembersGroupsList();
    }
  }

  openMembersManager = () => {
    const { openModal, category } = this.props;
    openModal(MODAL_TYPE_CATEGORIES_MANAGER, {
      categoryId: category._id,
      openPanels: [MANAGE_MEMBERS],
    });
  };

  render() {
    const { t, showCreatePostAction, category, type, ...rest } = this.props;
    return (
      <EmptyStates
        title={t('category-page.no-posts-yet')}
        content={t('category-page.create-new-post')}
        type={type}
        {...rest}
      >
        <div className={styles.wrapper}>
          {showCreatePostAction && (
            <CreatePostButton
              className={styles.primaryButton}
              categorySlug={category.slug}
              postTypes={category.postTypes}
              isSmall={false}
              isDropdown={false}
            />
          )}
        </div>
      </EmptyStates>
    );
  }
}

NoPosts.propTypes = {
  category: PropTypes.object,
  showCreatePostAction: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  t: PropTypes.func,
  type: PropTypes.string,
};

const mapRuntimeToProps = (state, { category, canSee }, actions) => {
  const showCreatePostAction =
    category && (!category.isWriteProtected || canSee('create-post', 'category', category));

  return {
    fetchMembersGroupsList: actions.fetchMembersGroupsList,
    showCreatePostAction,
    openModal: actions.openModal,
  };
};

export default flowRight(withPermissions, connect(mapRuntimeToProps), withTranslate)(NoPosts);
