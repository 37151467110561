import { get } from 'lodash';
import { isContentStateEmpty } from './content-state-utils';
import hasActiveUploads from './has-active-uploads';
import { createLSFormStore } from '../../common/components/fast-form/external-ls-store';

export default {
  formName: 'comment',
  resetOnUnmount: true,
  externalStore: (options, compProps, config, formName) => {
    if (!compProps.isLocalDraftEnabled) {
      return null;
    }
    const isEdit = get(compProps, 'postId') === undefined;
    return isEdit ? null : createLSFormStore(`${compProps.postId}-${formName}`);
  },
  validate: ({ content }) => {
    if (isContentStateEmpty(content)) {
      return { content: 'empty' };
    }

    if (hasActiveUploads(content)) {
      return { content: 'uploading' };
    }
  },
};
