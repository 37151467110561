import React from 'react';
import PropTypes from 'prop-types';
import styles from './sticky-container.scss';

const HTML_TAG = 'HTML';

class StickyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();

    this.state = {
      topOffset: this.props.defaultOffset,
      refNodes: null,
    };
  }

  componentDidMount() {
    this.setRefNodes();
    document.addEventListener('scroll', this.handleOverlap);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleOverlap);
  }

  setRefNodes() {
    if (this.containerRef.current) {
      this.setState({ refNodes: this.containerRef.current.getElementsByTagName('*') });
    }
  }

  getOverlapPercentage = (rectA, rectB) => {
    const { x: x1A, y: y1A, width: widthA, height: heightA } = rectA.getBoundingClientRect();
    const { x: x1B, y: y1B, width: widthB, height: heightB } = rectB.getBoundingClientRect();
    const x2A = x1A + widthA;
    const y2A = y1A + heightA;
    const x2B = x1B + widthB;
    const y2B = y1B + heightB;
    const rectAArea = heightA * widthA;
    const rectBArea = heightB * widthB;
    const overlapArea =
      Math.max(0, Math.min(x2A, x2B) - Math.max(x1A, x1B)) *
      Math.max(0, Math.min(y2A, y2B) - Math.max(y1A, y1B));

    return (overlapArea / (rectAArea + rectBArea - overlapArea)) * 100;
  };

  shouldChangeTopOffset = element => {
    if (element.tagName === HTML_TAG) {
      return false;
    } else if (this.checkIfIsFullScreen(element)) {
      return false;
    } else {
      const overlapPercentage = this.getOverlapPercentage(element, this.containerRef.current);
      return overlapPercentage < 8;
    }
  };

  handleOverlap = () => {
    const element = this.getElementAtContainerPos();
    const isOverlaped = element && !this.checkIfBelongsToRefNodes(element);
    if (isOverlaped && this.shouldChangeTopOffset(element)) {
      const { height, top } = element.getBoundingClientRect();
      const topOffset = height + top + this.props.defaultOffset;
      this.setState({ topOffset });
    }
  };

  checkIfContainerIsAtTheTop = element => {};
  checkIfBelongsToRefNodes = element => {
    const { refNodes } = this.state;
    let status = false;
    Array.prototype.forEach.call(refNodes, node => {
      if (element === node) {
        status = true;
      }
    });

    return status;
  };

  getElementAtContainerPos = () => {
    const { left, top, width } = this.containerRef.current.getBoundingClientRect();
    const middle = left + width / 2;
    let element = document.elementFromPoint(middle, top);
    if (element === null) {
      element = document.elementFromPoint(left, top);
    }

    return element;
  };

  checkIfIsFullScreen = element => {
    const { height, width } = element.getBoundingClientRect();
    const widthMatchRatio = Math.ceil((width / window.innerWidth) * 100);
    const heightMatchRatio = Math.ceil((height / window.innerHeight) * 100);

    return widthMatchRatio >= 100 && heightMatchRatio >= 100;
  };

  render() {
    const { topOffset } = this.state;
    return (
      <div ref={this.containerRef} style={{ top: `${topOffset}px` }} className={styles.container}>
        {this.props.children}
      </div>
    );
  }
}

StickyContainer.propTypes = {
  children: PropTypes.node,
  defaultOffset: PropTypes.number,
};

StickyContainer.defaultProps = {
  defaultOffset: 0,
};

export default StickyContainer;
