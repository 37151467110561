import React from 'react';

export const VoteDownIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="13" viewBox="0 0 24 13" {...props}>
    <path
      fillRule="evenodd"
      d="M12.735 12.204L22.45 1.678A1 1 0 0 0 21.716 0H2.284a1 1 0 0 0-.735 1.678l9.716 10.526a1 1 0 0 0 1.47 0z"
    />
  </svg>
);

export const VoteDownIconMobile = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12" {...props}>
    <path d="M10.707 9.293l7.586-7.586A1 1 0 0 0 17.586 0H2.414a1 1 0 0 0-.707 1.707l7.586 7.586a1 1 0 0 0 1.414 0z" />
  </svg>
);
