import { get, noop } from 'lodash';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Page from '../page';
import styles from './invalid-app-state-page.scss';
import { INVALID_APP_STATE_PAGE } from '../../constants/page-names';

const BACKUP_TRANSLATIONS = {
  'broken-state.title': 'Something Isn’t Working…',
  'broken-state.main-message': 'Refresh the page to try again.',
  'broken-state.btn-text': 'Refresh Page',
};

export class InvalidAppStatePage extends React.Component {
  componentDidMount() {
    const pageOpened = get(this.props, 'actions.pageOpened', noop);
    pageOpened({ page: INVALID_APP_STATE_PAGE });
  }
  render() {
    const t = key => get(this.props, `state.translations[${key}]`, BACKUP_TRANSLATIONS[key]);
    console.error(get(this.props, 'error'));
    return (
      <Page className={styles.container}>
        <div tabIndex="0" className={styles.innerContainer}>
          <h2 className={classNames(styles.title, 'invalid-app-state-page-title')}>
            {t('broken-state.title')}
          </h2>
          <span className={classNames(styles.body, 'invalid-app-state-page-body')}>
            {t('broken-state.main-message')}
          </span>
          <button
            onClick={() => window.location.reload()}
            className={classNames(
              styles.button,
              'button-primary-text-color',
              'button-background-color',
            )}
          >
            {t('broken-state.btn-text')}
          </button>
        </div>
      </Page>
    );
  }
}

InvalidAppStatePage.propTypes = {
  t: PropTypes.func,
};

export default InvalidAppStatePage;
