import React from 'react';
import Loader from '../loader';
import styles from './loader-box.scss';

const LoaderBox = () => (
  <div className={styles.container} data-hook="loader-box">
    <Loader />
  </div>
);

export default LoaderBox;
