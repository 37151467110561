import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Link from '../link/internal-link';
import withTranslate from '../../hoc/with-translate';
import styles from './post-title.scss';
import { DISCUSSION } from '../../constants/post-types';

const renderCategoryLink = (category, Link, t) => {
  const to = `/${category.slug}`;
  return (
    <div className={styles.link}>
      {t('post-list-item.title-in-category')}&nbsp;
      <Link to={to} className={classNames('forum-text-color', 'forum-link-hover-color')}>
        <span className={styles.categoryLink}>{category.label}</span>
      </Link>
    </div>
  );
};

const PostSubTitle = ({
  className,
  category,
  t,
  type,
  showPostType,
  showCategoryLink,
  isQACategoriesEnabled,
  post,
}) => {
  return (
    <div className={classNames(className, styles[type], styles.container)}>
      {isQACategoriesEnabled && showPostType && (
        <span>
          {t(
            post.postType === DISCUSSION
              ? 'post-title.discussion-post-type'
              : 'post-title.question-post-type',
          )}
          &nbsp;
        </span>
      )}
      {showCategoryLink && renderCategoryLink(category, Link, t)}
    </div>
  );
};

PostSubTitle.propTypes = {
  className: PropTypes.string,
  category: PropTypes.object,
  post: PropTypes.object.isRequired,
  t: PropTypes.function,
  type: PropTypes.string,
  showCategoryLink: PropTypes.boolean,
};

PostSubTitle.defaultProps = {
  showCategoryLink: false,
  showPostType: false,
};

PostSubTitle.LARGE = 'large';
PostSubTitle.SINGLE = 'single';
PostSubTitle.SEARCH = 'search';
PostSubTitle.MOBILE = 'mobile';

export default flowRight(withTranslate)(PostSubTitle);
