import React from 'react';

export const EditIcon = props => (
  <svg
    width="19px"
    height="19px"
    viewBox="0 0 19 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.4978611,5.33744164 L3,13.8117632 L3,16.011763 L5.19999981,16.011763 L13.6860912,7.52567167 L11.4978611,5.33744164 Z M12.1861842,4.65102529 L14.3845079,6.8272549 L16,5.21176283 L13.8299999,3.01176302 L12.1861842,4.65102529 Z M2,13.534765 C2,13.4817217 2.02107137,13.4308509 2.05857864,13.3933436 L13.1590291,2.29289322 C13.5495533,1.90236893 14.1827183,1.90236893 14.5732426,2.29289322 L16.7188698,4.43852041 C17.1093941,4.8290447 17.1093941,5.46220968 16.7188698,5.85273397 L5.61841939,16.9531844 C5.58091212,16.9906917 5.53004133,17.011763 5.47699804,17.011763 L2.2,17.011763 C2.08954305,17.011763 2,16.92222 2,16.811763 L2,13.534765 Z"
      id="Edit"
    />
  </svg>
);
