import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { getPreviousMatches, getCurrentMatch } from '../../../common/router/router-selectors';
import PageCover from '../page-cover';
import { COVER_TYPE_COLOR } from '../../constants/cover-types';
import { TEXT_ALIGNMENT_CLASS_NAMES } from '../../constants/text-alignment';
import forDevice from '../../hoc/for-device';
import styles from './page-description.scss';
import {
  getHeaderHeight,
  getMainPageHeaderHeight,
  getCategoryTextAlignment,
  getMainPageHeaderTextAlignment,
} from '../../selectors/app-settings-selectors';

const DEFAULT_HEIGHT = 200;

const getContainerStyle = (textColor = {}, headerHeight) => {
  const style = { color: textColor.color };
  if (headerHeight) {
    style.height = headerHeight;
  }
  return style;
};

export class PageDescription extends React.Component {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();
  }

  componentDidMount() {
    const { focusHeaderOnMount, routerHistory, currentRoute } = this.props;
    if (routerHistory && currentRoute) {
      const isFirstLoad = routerHistory.length === 1;
      //second condition is valid when forum has only one category and was redirected to it
      const isHomePage = currentRoute.pathname === '/' || currentRoute.prevMatches[0];

      if (focusHeaderOnMount && this.headerRef.current && !(isFirstLoad && isHomePage)) {
        this.headerRef.current.focus();
      }
    }
  }

  componentDidUpdate(lastProps) {
    if (
      this.props.focusHeaderOnMount &&
      this.props.title !== lastProps.title &&
      this.headerRef.current
    ) {
      this.headerRef.current.focus();
    }
  }

  render() {
    const {
      title,
      description,
      cover,
      coverType,
      overlayColor,
      backgroundColor,
      textColor = {},
      categoryTextAlignment,
      children,
      isCoverVisible,
      headerHeight,
    } = this.props;

    const textAlignmentClassName = styles[TEXT_ALIGNMENT_CLASS_NAMES[categoryTextAlignment]];
    const containerClassName = classNames(
      styles.container,
      'default-desktop-header-text-color',
      'page-description',
      {
        [textAlignmentClassName]: textAlignmentClassName,
      },
    );

    const NoCover = ({ children, className }) => <div className={className}>{children}</div>;

    const CoverComponent = isCoverVisible ? PageCover : NoCover;
    // if there is no image, any text and no background color, it would show empty white, block, so we hide it
    const hasNoContent =
      title === '' && description === '' && coverType === COVER_TYPE_COLOR && !backgroundColor;

    if (!isCoverVisible || hasNoContent) {
      return null;
    }

    return (
      <div className={containerClassName} style={getContainerStyle(textColor, headerHeight)}>
        <CoverComponent
          cover={cover}
          coverType={coverType}
          overlayColor={overlayColor}
          backgroundColor={backgroundColor}
          height={DEFAULT_HEIGHT}
          hasActiveState={false}
        >
          <div className={styles.wrapper}>
            <div
              className={classNames(
                styles.titleWrapper,
                'page-description-title-font',
                'page-description__title',
              )}
              data-hook="page-description__title"
            >
              <h1 tabIndex="-1" ref={this.headerRef} className={styles.title}>
                {title}
              </h1>
            </div>
            <div
              className={classNames(styles.descriptionWrapper, 'page-description-font')}
              data-hook="page-description__description"
            >
              <p className={styles.description}>{description}</p>
            </div>
            {children}
          </div>
        </CoverComponent>
      </div>
    );
  }
}

PageDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cover: PropTypes.object,
  coverType: PropTypes.string,
  overlayColor: PropTypes.object,
  backgroundColor: PropTypes.object,
  textColor: PropTypes.object,
  categoryTextAlignment: PropTypes.number.isRequired,
  children: PropTypes.node,
  isCoverVisible: PropTypes.bool.isRequired,
  headerHeight: PropTypes.number,
  focusHeaderOnMount: PropTypes.bool,
  type: PropTypes.string,
};

PageDescription.defaultProps = {
  isCoverVisible: true,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  headerHeight: getHeaderHeight(state, host.style),
  categoryTextAlignment: getCategoryTextAlignment(state, host.style),
  routerHistory: getPreviousMatches(state),
  currentRoute: getCurrentMatch(state),
});

export const PageDescriptionMobile = flowRight(connect(mapRuntimeToProps))(PageDescription);

export const PageDescriptionDesktop = flowRight(connect(mapRuntimeToProps))(PageDescription);

export default forDevice(PageDescriptionMobile, PageDescriptionDesktop);

const mapRuntimeToPropsMain = (state, ownProps, actions, host) => ({
  headerHeight: getMainPageHeaderHeight(state, host.style),
  categoryTextAlignment: getMainPageHeaderTextAlignment(state, host.style),
  routerHistory: getPreviousMatches(state),
  currentRoute: getCurrentMatch(state),
});

export const MainPageDescriptionMobile = flowRight(connect(mapRuntimeToPropsMain))(PageDescription);

export const MainPageDescriptionDesktop = flowRight(connect(mapRuntimeToPropsMain))(
  PageDescription,
);

export const MainPageDescription = forDevice(MainPageDescriptionMobile, MainPageDescriptionDesktop);
