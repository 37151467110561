import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Interpolate } from 'react-i18next';
import TypingListLoader from '../typing-list-loader';
import { HorizontalSeparator } from '../separator';
import withTranslate from '../../hoc/with-translate';
import styles from './typing-list.scss';

const TypingList = ({ users = [] }) => {
  if (users.length) {
    return (
      <div
        className={classNames(
          styles.typing,
          'typing-list',
          'forum-card-border-color',
          'forum-text-color',
          'forum-card-background-color',
        )}
        data-hook="typing-indicator"
      >
        <div className={classNames(styles.background, 'forum-card-background-color')} />
        <div className={styles.content}>
          <HorizontalSeparator className={styles.separator} />
          <div className={styles.wrapper}>
            <Interpolate
              i18nKey="typing-list.is-typing"
              options={{ count: users.length }}
              count={users.length}
              member={<span className={styles.member}>{users[0].name}</span>}
            />
            <TypingListLoader />
          </div>
        </div>
      </div>
    );
  }
  return null;
};

TypingList.propTypes = {
  users: PropTypes.array,
  t: PropTypes.func,
};

export default withTranslate(TypingList);
