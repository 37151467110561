import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import PostShareButton from '../post-share-button';
import share from '../../services/share';
import getOuterUrl from '../../services/get-outer-url';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import styles from './post-social-actions.scss';
import { connect } from '../../../common/components/runtime-context';
import { getSectionUrl } from '../../../common/store/location/location-selectors';
import { flowRight } from 'lodash';

const containerId = 'post-social-actions';

export class PostSocialActions extends Component {
  handleLinkButtonClick = () => {
    this.props.openShareLinkModal(this.props.href, containerId);
  };

  handleShare = socialNetwork => {
    share(socialNetwork, this.props.href);
  };

  render() {
    const { className, t } = this.props;

    return (
      <div
        className={classNames(
          styles.container,
          className,
          'forum-text-color',
          this.props.titleFontClassName,
        )}
        tabIndex="-1"
        id={containerId}
      >
        <div className={styles.content}>
          <span className={styles.button}>
            <PostShareButton
              type="facebook"
              title={t('share-button.via-facebook')}
              onClick={this.handleShare}
            />
          </span>
          <span className={styles.button}>
            <PostShareButton
              type="twitter"
              title={t('share-button.via-twitter')}
              onClick={this.handleShare}
            />
          </span>
          <span className={styles.button}>
            <PostShareButton
              type="link"
              title={t('share-button.via-link')}
              onClick={this.handleLinkButtonClick}
            />
          </span>
        </div>
      </div>
    );
  }
}

PostSocialActions.propTypes = {
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
  openShareLinkModal: PropTypes.func.isRequired,
  titleFontClassName: PropTypes.string,
  t: PropTypes.func.isRequired,
  href: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, ownProps) => ({
  href: getOuterUrl(ownProps.path, getSectionUrl(state)),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withFontClassName,
  withTranslate,
)(PostSocialActions);
