import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import ProtectedButton from '../../containers/protected-button';
import LikeButton from '../like-button';
import ReplyButton from '../reply-button';
import PostSocialActions from '../../containers/post-social-actions';
import { getIsSocialSharingLinksEnabled } from '../../selectors/app-settings-selectors';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../hoc/with-translate';
import withAuth from '../../hoc/with-auth';
import styles from './post-main-actions.scss';
import { isEditor, isPreview } from '../../../common/store/basic-params/basic-params-selectors';
import CommentingDisabled from '../commenting-disabled';
import { LIKE_POST } from '../../constants/interactions';

const PostMainActions = ({
  can,
  category,
  post,
  onLikeClick,
  onReplyClick,
  t,
  forPublicUser,
  isSocialSharingLinksEnabled,
  shouldShowShareButton,
  isMobile,
}) => {
  const canShare =
    can('share', 'post', post) && shouldShowShareButton && isSocialSharingLinksEnabled;
  const likeCount = post.likeCount || 0;
  const isCommentsDisabled = get(post, 'isCommentsDisabled', false);
  return (
    <div className={styles.container}>
      {canShare && (
        <PostSocialActions
          className={styles.socialActions}
          path={`/${category.slug}/${post.slug}`}
        />
      )}
      <div className={styles.buttonContainer}>
        {!isCommentsDisabled ? (
          <ReplyButton userName={get(post, 'owner.name')} onClick={onReplyClick} type={'comment'} />
        ) : isMobile ? (
          <CommentingDisabled message={t('post-page.commenting-disabled')} isInPostMainAction />
        ) : (
          <div />
        )}
        <span className={styles.buttonWrapper}>
          <span
            className={classNames(styles.likeCount, 'post-main-actions__like-count')}
            data-hook="post-main-actions__like-count"
          >
            {t('post-main-actions.like-count', { count: likeCount })}
          </span>
          <ProtectedButton
            className={styles.button}
            onClick={forPublicUser(onLikeClick)}
            actionDetails={{ action: LIKE_POST, args: [post._id] }}
          >
            <LikeButton
              onClick={onLikeClick}
              isLiked={post.isLiked}
              likeCount={likeCount}
              isDisabled={post.isLikeInProgress}
            />
          </ProtectedButton>
        </span>
      </div>
    </div>
  );
};

PostMainActions.propTypes = {
  category: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  onReplyClick: PropTypes.func.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  can: PropTypes.func,
  t: PropTypes.func,
  forPublicUser: PropTypes.func,
  isSocialSharingLinksEnabled: PropTypes.bool,
  shouldShowShareButton: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isSocialSharingLinksEnabled: getIsSocialSharingLinksEnabled(state, host.style),
  shouldShowShareButton: !isEditor(state) && !isPreview(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withPermissions,
  withTranslate,
  withAuth,
)(PostMainActions);
