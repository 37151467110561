import PropTypes from 'prop-types';
import React from 'react';
import { isMultiplePostTypeCategory } from '../../services/categories-service';
import PostListItem from '../post-list-item';
import CreatePostCta from '../create-post-cta-yak';
import ItemContainer from '../post-list-mobile-common/item-container';
import NoPostsFiltered from '../no-posts-filtered';
import { HorizontalSeparator } from '../separator';

const PostListMobileClassic = ({
  category,
  posts,
  query,
  onLikeClick,
  showCreatePostAction,
  showCategoryLink,
  showPinIcon,
  showLockIcon,
  showTopComment,
  showMoreButton,
  showViewCount,
  hasActiveFilter,
}) => (
  <div>
    {showCreatePostAction && (
      <React.Fragment>
        <ItemContainer>
          <CreatePostCta
            categorySlug={category.slug}
            isMultiplePostTypeCategory={isMultiplePostTypeCategory(category)}
          />
        </ItemContainer>
        <HorizontalSeparator isLarge />
      </React.Fragment>
    )}
    {!posts.length && hasActiveFilter && <NoPostsFiltered />}
    {posts.map((post, idx) => (
      <React.Fragment>
        <ItemContainer key={post._id}>
          <PostListItem
            post={post}
            query={query}
            type={PostListItem.MOBILE}
            onLikeClick={onLikeClick}
            showCategoryLink={showCategoryLink}
            showContent={false}
            showPinIcon={showPinIcon}
            showLockIcon={showLockIcon}
            showTopComment={showTopComment}
            showMoreButton={showMoreButton}
            showViewCount={showViewCount}
            showSeparator={false}
            isMultiplePostTypeCategory={isMultiplePostTypeCategory(category)}
          />
        </ItemContainer>
        {idx < posts.length - 1 ? <HorizontalSeparator isLarge /> : null}
      </React.Fragment>
    ))}
  </div>
);

PostListMobileClassic.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  showCreatePostAction: PropTypes.bool,
  showCategoryLink: PropTypes.bool,
  showPinIcon: PropTypes.bool,
  showLockIcon: PropTypes.bool,
  showTopComment: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showViewCount: PropTypes.bool,
  hasActiveFilter: PropTypes.bool,
};

PostListMobileClassic.defaultProps = {
  showPinIcon: true,
  showLockIcon: true,
  showTopComment: true,
  showMoreButton: true,
  showViewCount: true,
};

export default PostListMobileClassic;
