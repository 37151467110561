import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import withTranslate from '../../hoc/with-translate';
import styles from './like-button.scss';

class LikeButton extends Component {
  state = {
    isAnimationEnabled: false,
  };

  constructor(props) {
    super(props);

    this.handleAnimation = this.handleAnimation.bind(this);
  }

  handleAnimation(event) {
    if (this.props.isDisabled) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    if (!this.props.isLiked) {
      this.setState({ isAnimationEnabled: true });
    }
  }

  render() {
    const { isLiked, likeCount, t } = this.props;
    const { isAnimationEnabled } = this.state;
    const likedStatusLabel = isLiked ? t('like-button.is-liked') : t('like-button.is-unliked');

    return (
      <div
        onClick={this.handleAnimation}
        className={classNames(
          styles.heart,
          { [styles.active]: isLiked },
          { [styles.isAnimationEnabled]: isAnimationEnabled },
        )}
        aria-label={`${t('like-button.total-likes', { count: likeCount })}. ${likedStatusLabel}`}
        data-hook="like-button"
      >
        <div className={classNames(styles.pos1)}>
          <div className={classNames(styles.heartSparkle)} />
        </div>
        <div className={classNames(styles.pos2)}>
          <div className={classNames(styles.heartSparkle)} />
        </div>
        <div className={classNames(styles.pos3)}>
          <div className={classNames(styles.heartSparkle)} />
        </div>
        <div className={classNames(styles.pos4)}>
          <div className={classNames(styles.heartSparkle)} />
        </div>
        <div className={classNames(styles.heartRing)} />
        <svg className={classNames(styles.heartBody)} width="18" viewBox="0 0 19 19">
          <path
            id="a"
            d="M17.234 5.47a3.919 3.919 0 0 0-2.315-2.263c-2.022-.6-4.21.137-5.424 1.827C8.291 3.38 6.15 2.646 4.152 3.207a3.76 3.76 0 0 0-2.405 2.35 4.94 4.94 0 0 0 .57 4.264c1.603 2.698 6.76 5.927 6.938 6.092a.454.454 0 0 0 .534 0c.267-.174 5.343-3.229 6.938-6.092a5.16 5.16 0 0 0 .507-4.352z"
          />
        </svg>
      </div>
    );
  }
}

LikeButton.propTypes = {
  isLiked: PropTypes.bool,
  likeCount: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default withTranslate(LikeButton);
