let logged = false;

export function logPageDef(state) {
  if (logged) {
    return;
  }
  console.log('>> PAGE DEFINITION: BASE <<');
  console.log(
    JSON.stringify(
      {
        baseUrl: window.location.origin,
        sectionUrl: window.location.pathname.slice(1),
        siteId: window.rendererModel.siteInfo.siteId,
        metaSiteId: window.rendererModel.metaSiteId,
        instanceId: Object.values(window.rendererModel.clientSpecMap).find(
          m => m.appDefinitionId === '14724f35-6794-cd1a-0244-25fd138f9242',
        ).instanceId,
        smCollectionId: Object.values(window.rendererModel.clientSpecMap).find(
          m => m.type === 'sitemembers',
        ).smcollectionId,
        appDefId: '14724f35-6794-cd1a-0244-25fd138f9242',
      },
      null,
      2,
    ),
  );
  console.log('>> PAGE DEFINITION: USER <<');
  console.log(
    JSON.stringify(
      {
        uid: state.instanceValues.uid,
      },
      null,
      2,
    ),
  );

  logged = true;
}
