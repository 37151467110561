import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { isQACategoriesExperimentEnabled } from '../../selectors/experiments-selectors';
import { DISCUSSION } from '../../constants/post-types';
import DropdownButton from '../dropdown-button';
import DiscussionPostButton from '../create-discussion-button';
import QuestionPostButton from '../create-question-button';
import { HorizontalSeparator, VerticalSeparator } from '../separator';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withDeviceType from '../../hoc/with-device-type';
import styles from './create-post-button.scss';

const CreatePostButton = ({
  categorySlug,
  postTypes,
  isQACategoriesEnabled,
  isSmall,
  isDropdown,
  borderWidth,
  isMobile,
}) => {
  const NewPostButtonDropdown = () => {
    if (isDropdown) {
      return (
        <DropdownButton
          label="create-post-button.create-new-post"
          className={styles.dropdownButtons}
          dataHook={'create-post-cta'}
        >
          <DiscussionPostButton categorySlug={categorySlug} isExtended />
          <HorizontalSeparator />
          <QuestionPostButton categorySlug={categorySlug} isExtended isNewFeature />
        </DropdownButton>
      );
    }
    return (
      <div
        className={classNames(
          styles.buttons,
          'forum-card-border-color',
          'default-forum-card-background-color',
        )}
        style={{ borderWidth }}
      >
        <DiscussionPostButton categorySlug={categorySlug} isExtended />
        {isMobile ? (
          <HorizontalSeparator className={styles.horizontalSeparator} />
        ) : (
          <VerticalSeparator />
        )}
        <QuestionPostButton categorySlug={categorySlug} isExtended />
      </div>
    );
  };

  const renderCreatePostButton = () => {
    if (postTypes.length > 1) return <NewPostButtonDropdown />;
    return postTypes[0] === DISCUSSION ? (
      <DiscussionPostButton categorySlug={categorySlug} />
    ) : (
      <QuestionPostButton categorySlug={categorySlug} />
    );
  };

  return (
    <div>
      {isQACategoriesEnabled && renderCreatePostButton()}
      {!isQACategoriesEnabled && (
        <DiscussionPostButton categorySlug={categorySlug} isSmall={isSmall} />
      )}
    </div>
  );
};

CreatePostButton.propTypes = {
  categorySlug: PropTypes.string,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  isSmall: PropTypes.bool,
  isDropdown: PropTypes.bool,
  borderWidth: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
};

CreatePostButton.defaultProps = {
  postTypes: ['question', 'discussion'],
  isDropdown: true,
};

const mapRuntimeToProps = state => ({
  isQACategoriesEnabled: isQACategoriesExperimentEnabled(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withCardBorderWidth,
  withDeviceType,
)(CreatePostButton);
