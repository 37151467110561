import { flowRight } from 'lodash';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import {
  SORT_BY_BEST_MATCH,
  SORT_BY_LAST_ACTIVITY,
  SORT_BY_NEWEST,
  SORT_BY_COMMENTS,
  SORT_BY_MOST_VIEWS,
  SORT_BY_LIKES,
} from '../../constants/sorting';
import { getSearchPageSorting } from '../../selectors/sorting-selectors';
import withTranslate from '../../hoc/with-translate';
import { getRouteParams } from '../../../common/router/router-selectors';
import { decodeQuery } from '../../services/query-encoding';

export const getOptions = t => [
  { label: t('post-sorting.bestMatch'), value: SORT_BY_BEST_MATCH },
  { label: t('post-sorting.sortByLastActivity'), value: SORT_BY_LAST_ACTIVITY },
  { label: t('post-sorting.newest'), value: SORT_BY_NEWEST },
  { label: t('post-sorting.comments'), value: SORT_BY_COMMENTS },
  { label: t('post-sorting.views'), value: SORT_BY_MOST_VIEWS },
  { label: t('post-sorting.likes'), value: SORT_BY_LIKES },
];

class SortingSelectHeadless extends Component {
  onChange = value => {
    const { setSearchPageSorting, query, search } = this.props;
    setSearchPageSorting(value);
    search({ query, sort: value });
  };

  render() {
    const { t, sort, children } = this.props;
    return children({ options: getOptions(t), value: sort, onChange: this.onChange });
  }
}

SortingSelectHeadless.propTypes = {
  t: PropTypes.func,
  onChange: PropTypes.func,
  setSearchPageSorting: PropTypes.func,
  search: PropTypes.func,
  sort: PropTypes.string,
  children: PropTypes.func,
  query: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  sort: getSearchPageSorting(state),
  search: actions.search,
  setSearchPageSorting: actions.setSearchPageSorting,
  query: decodeQuery(getRouteParams(state).query),
});

export default flowRight(withTranslate, connect(mapRuntimeToProps))(SortingSelectHeadless);
