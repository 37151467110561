import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import { VerticalSeparator } from '../separator';
import Button from '../button';
import ensureAuth from '../../hoc/ensure-auth';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import styles from './login-to-comment-desktop.scss';

const ButtonSecure = ensureAuth(Button);

const Section = ({ loginMode, borderWidth, buttonClicked, t }) => {
  const title = t(`login-to-comment.desktop.${loginMode}.title`);
  const description = t(`login-to-comment.desktop.${loginMode}.description`);
  const buttonText = t(`login-to-comment.desktop.${loginMode}.button`);
  const isSecondary = loginMode === 'login';

  const sectionClassName = classNames(styles.section, 'forum-card-border-color');
  return (
    <div className={sectionClassName} style={{ borderWidth }}>
      <div className={styles.sectionTitle}>{title}</div>
      <div className={styles.sectionDescription}>{description}</div>
      <ButtonSecure
        requestLoginMode={loginMode}
        isSecondary={isSecondary}
        isSmall
        beforeRequestLogin={() => buttonClicked({ name: 'log_in_to_comment', type: loginMode })}
      >
        {buttonText}
      </ButtonSecure>
    </div>
  );
};

Section.propTypes = {
  loginMode: PropTypes.oneOf(['login', 'signup']).isRequired,
  borderWidth: PropTypes.number.isRequired,
  buttonClicked: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const LoginToCommentDesktop = ({ contentFontClassName, borderWidth, buttonClicked, t }) => {
  const containerClassName = classNames(
    styles.container,
    'forum-text-color',
    'forum-card-background-color',
    'forum-card-border-color',
    contentFontClassName,
  );

  const sectionProps = { borderWidth, buttonClicked, t };
  return (
    <div className={containerClassName} style={{ borderWidth }}>
      <div className={styles.content}>
        <Section {...sectionProps} loginMode="login" />
        <div className={styles.separator}>
          <VerticalSeparator />
        </div>
        <Section {...sectionProps} loginMode="signup" />
      </div>
    </div>
  );
};

LoginToCommentDesktop.propTypes = {
  contentFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  borderWidth: PropTypes.number.isRequired,
  buttonClicked: PropTypes.func.isRequired,
};

export default flowRight(
  withCardBorderWidth,
  withTranslate,
  withFontClassName,
)(LoginToCommentDesktop);
