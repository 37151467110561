import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Masonry from '../../../common/components/masonry';
import { CARD_SIZE_SMALL, CARD_SIZE_MEDIUM } from '../../constants/card-sizes';
import { isMultiplePostTypeCategory } from '../../services/categories-service';
import PostListItem from '../post-list-item';
import { SortingSelectCategoryPageDesktop } from '../sorting-select-category';
import { FilterSelectPostTypeDesktop } from '../filter-select-post-type';
import CreatePostButton from '../create-post-button/create-post-button';
import { getCardSize } from '../../selectors/app-settings-selectors';
import { isQACategoriesExperimentEnabled } from '../../selectors/experiments-selectors';
import { connect } from '../../../common/components/runtime-context';
import styles from './post-list-masonry-responsive.scss';
import NoPostsFiltered from '../no-posts-filtered';

const TYPES = {
  [CARD_SIZE_SMALL]: 'small',
  [CARD_SIZE_MEDIUM]: 'medium',
};

const getMasonryBreakpointsByCardSize = cardSize => {
  if (cardSize === CARD_SIZE_SMALL) {
    return {
      default: 3,
      750: 2,
      600: 1,
    };
  } else if (cardSize === CARD_SIZE_MEDIUM) {
    return {
      default: 2,
      600: 1,
    };
  }
};

const renderCreateNewPostButton = category => {
  return <CreatePostButton categorySlug={category.slug} postTypes={category.postTypes} />;
};

const renderSortingSelect = () => (
  <div className={styles.selectContainer}>
    <SortingSelectCategoryPageDesktop />
  </div>
);

const renderFilterSelect = () => (
  <div className={styles.selectContainer}>
    <FilterSelectPostTypeDesktop />
  </div>
);

const Controls = ({
  isQACategoriesEnabled,
  category,
  showCreatePostAction,
  isMultiplePostTypeCategory,
}) => {
  return (
    <div className={classNames(styles.controls)} data-hook="post-list-masonry-controls">
      {isQACategoriesEnabled && isMultiplePostTypeCategory && renderFilterSelect()}
      {renderSortingSelect()}
      {showCreatePostAction && renderCreateNewPostButton(category)}
    </div>
  );
};

Controls.propTypes = {
  category: PropTypes.object,
  showCreatePostAction: PropTypes.bool,
};

const PostListMasonryResponsive = ({
  category,
  posts,
  onLikeClick,
  showCreatePostAction,
  showCategoryLink,
  cardSize,
  isQACategoriesEnabled,
  hasActiveFilter,
}) => {
  const className = classNames(styles.masonry);
  return (
    <div>
      <Controls
        category={category}
        showCreatePostAction={showCreatePostAction}
        isMultiplePostTypeCategory={isMultiplePostTypeCategory(category)}
        isQACategoriesEnabled={isQACategoriesEnabled}
      />
      {!posts.length && hasActiveFilter ? (
        <NoPostsFiltered />
      ) : (
        <Masonry
          columnClassName={styles.masonryColumn}
          containerWidth={960}
          breakpointCols={getMasonryBreakpointsByCardSize(cardSize)}
          className={className}
          data-hook="post-list"
        >
          {posts.map(post => (
            <div
              className={classNames(styles.listItem, 'post-list__post-list-item')}
              key={post._id}
              id={post._id}
              data-hook="post-list-item"
            >
              <PostListItem
                post={post}
                onLikeClick={onLikeClick}
                showCategoryLink={showCategoryLink}
                type={TYPES[cardSize]}
                isMultiplePostTypeCategory={isMultiplePostTypeCategory(category)}
              />
            </div>
          ))}
        </Masonry>
      )}
    </div>
  );
};

PostListMasonryResponsive.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  query: PropTypes.string,
  showCreatePostAction: PropTypes.bool,
  showCategoryLink: PropTypes.bool,
  cardSize: PropTypes.number,
  hasActiveFilter: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  cardSize: getCardSize(state, host.style),
  isQACategoriesEnabled: isQACategoriesExperimentEnabled(state),
});

export default flowRight(connect(mapRuntimeToProps))(PostListMasonryResponsive);
