import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import Link from '../link/internal-link';
import MoreButton from '../more-button';
import CategoryActions from '../../containers/category-actions';
import withTranslate from '../../hoc/with-translate';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withAuth from '../../hoc/with-auth';
import styles from './subcategory-list-item-desktop.scss';
import FollowButton from '../follow-button';
import withPermissions from '../../hoc/with-permissions';
import { isPrivate } from '../../../common/services/category-privacy-utils';
import { LockEmptyIcon } from '../icons/lock-empty-icon';
import { CurrencySignIcon } from '../icons/currency-sign-icon';
import { hasCategoryPaidPlans } from '../../selectors/paid-plans-selectors';

export const SubcategoryListItemDesktop = props => {
  const {
    category,
    t,
    titleFontClassName,
    contentFontClassName,
    isViewsCountEnabled,
    fetchCategoryPosts,
    resetPostTypeFilter,
    can,
    hasCategoryPP,
  } = props;
  const shouldShowMoreButton = can('edit', 'category', category);
  const className = classNames(styles.container, 'subcategory-list-item');
  const categoryLink = `/${category.slug}`;

  const handlePostFetch = () => {
    resetPostTypeFilter();
    fetchCategoryPosts({ categoryId: category._id, page: 1 });
  };

  const linkToPosts = (children, dataHook) => (
    <Link
      className={classNames('forum-text-color', 'forum-link-hover-color', styles.postLink)}
      to={categoryLink}
      onMouseDown={handlePostFetch}
      data-hook={dataHook}
    >
      {children}
    </Link>
  );
  const moreButton = (
    <MoreButton size={MoreButton.SIZE_SMALL}>
      <CategoryActions hideFollowButton category={category} />
    </MoreButton>
  );
  const categoryActions = (
    <div className={styles.actionsContainer}>
      <FollowButton
        category={category}
        className={shouldShowMoreButton ? styles.followButton : ''}
        data-hook="subcategory-list-item__follow-button"
        size={FollowButton.SIZE_SMALL}
      >
        {t(category.isSubscribed ? 'category-actions.unfollow' : 'category-actions.follow')}
      </FollowButton>
      {shouldShowMoreButton && moreButton}
    </div>
  );
  const titleText = (
    <h2
      name={category.label}
      className={classNames(styles.title)}
      data-hook="subcategory-list-item__title"
    >
      {category.label}
    </h2>
  );
  const title = linkToPosts(titleText);

  let categoryBadge = null;

  if (isPrivate(category)) {
    const Icon = hasCategoryPP ? CurrencySignIcon : LockEmptyIcon;
    categoryBadge = (
      <span className={styles.categoryBadgeWrapper}>
        <Icon className="forum-icon-fill" />
      </span>
    );
  }
  return (
    <div
      className={classNames(className, contentFontClassName, 'forum-text-color')}
      data-hook="subcategory-list-item"
    >
      <div className={styles.titleWrapper}>
        <div className={classNames(titleFontClassName, styles.titleWithIcon)}>
          {title} {categoryBadge}
        </div>
        {categoryActions}
      </div>
      <p className={styles.statistics} data-hook="subcategory-list-item__statistics">
        {isViewsCountEnabled &&
          t('category-list-item.total-views', { count: category.totalViews || 0 })}
        {isViewsCountEnabled && ' | '}
        {t('category-list-item.total-posts', { count: category.totalPosts || 0 })}
      </p>
    </div>
  );
};

SubcategoryListItemDesktop.propTypes = {
  category: PropTypes.object.isRequired,
  t: PropTypes.func,
  borderWidth: PropTypes.number,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  isViewsCountEnabled: PropTypes.bool,
  fetchCategoryPosts: PropTypes.func,
  resetPostTypeFilter: PropTypes.func,
  hasCategoryPP: PropTypes.bool,
  can: PropTypes.func,
};

const mapRuntimeToProps = (state, { category }, actions) => ({
  fetchCategoryPosts: actions.fetchCategoryPosts,
  resetPostTypeFilter: actions.resetPostTypeFilter,
  hasCategoryPP: hasCategoryPaidPlans(state, category.groups),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withAuth,
  withCardBorderWidth,
  withFontClassName,
  withTranslate,
  withPermissions,
)(SubcategoryListItemDesktop);
