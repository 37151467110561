import React from 'react';

export const ReplyIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" {...props}>
    <path
      id="a"
      d="M9.5 13H15a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h3v2.5L9.5 13zm5.508.973H10l-2.578 2.572C6.637 17.328 6 17.067 6 15.97v-1.997H3.992A2 2 0 0 1 2 11.98V4.994C2 3.893 2.893 3 3.992 3h11.016A2 2 0 0 1 17 4.994v6.985a1.994 1.994 0 0 1-1.992 1.994z"
    />
  </svg>
);
