import { omit } from 'lodash';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import hoistNonReactStatics from 'hoist-non-react-statics';
import uri from 'urijs';
import createHocName from '../services/create-hoc-name';

const NAME = 'WithTranslate';

const showKeys =
  typeof window !== 'undefined' && uri(window.location).hasQuery('debug-translate', 'true');

export const withTranslateProps = ['i18n', 'lng', 't', 'tReady'];

export default function withTranslate(WrappedComponent) {
  class WithTranslate extends Component {
    static displayName = createHocName(NAME, WrappedComponent);

    render() {
      const props = omit(this.props, ['i18nLoadedAt']);

      if (showKeys) {
        props.t = key => key;
      }

      return <WrappedComponent {...props} />;
    }
  }

  hoistNonReactStatics(WithTranslate, WrappedComponent);

  return translate(['translation'])(WithTranslate);
}
