import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import CategorySelect from '../../components/category-select';
import BreadcrumbsWidget from '../breadcrumbs-widget';
import SearchInput from '../search-input';
import DesktopHeader from '../desktop-header';

const renderSearchInput = () => (
  <SearchInput
    isExpanded
    inputClassName="desktop-header-search-text-color desktop-header-search-font"
    borderClassName="desktop-header-search-border-background-color"
    iconClassName={classNames('desktop-header-search-icon-fill', 'button-hover-fill')}
  />
);

const InternalPageCategorySelectDesktopHeader = ({ params, ...props }) => (
  <DesktopHeader
    left={<CategorySelect params={params} />}
    right={<BreadcrumbsWidget searchInput={renderSearchInput()} params={params} />}
    {...props}
  />
);

InternalPageCategorySelectDesktopHeader.propTypes = {
  params: PropTypes.object.isRequired,
};

export default InternalPageCategorySelectDesktopHeader;
