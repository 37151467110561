import { flowRight } from 'lodash';
import React from 'react';
import styles from './login-page.scss';
import EmptyStates from '../../components/empty-states';
import LoginButton from '../../components/login-button';
import withTranslate from '../../hoc/with-translate';
import { getAllCategories } from '../../selectors/categories-selectors';
import { getLocation } from '../../../common/store/location/location-selectors';
import { connect } from '../../../common/components/runtime-context';
import { isPrivate } from '../../../common/services/category-privacy-utils';

const LoginPage = ({ t, category }) => (
  <EmptyStates
    className={styles.EmptyStates}
    title={t('login-screen.login-required')}
    content={t('login-screen.login-description')}
    type={isPrivate(category) ? 'specific_members_other' : 'members_only'}
  >
    <LoginButton />
  </EmptyStates>
);

const mapRuntimeToProps = state => {
  const categories = getAllCategories(state);
  const location = getLocation(state);

  return {
    category: categories.find(c => location.pathname.includes(c.slug)) || {},
  };
};

export default flowRight(connect(mapRuntimeToProps), withTranslate)(LoginPage);
