import React from 'react';

export const VoteUpIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="13" viewBox="0 7 24 13" {...props}>
    <path
      fillRule="evenodd"
      d="M12.735 7.796l9.716 10.526A1 1 0 0 1 21.716 20H2.284a1 1 0 0 1-.735-1.678l9.716-10.526a1 1 0 0 1 1.47 0z"
    />
  </svg>
);

export const VoteUpIconMobile = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12" {...props}>
    <path d="M10.707 2.707l7.586 7.586A1 1 0 0 1 17.586 12H2.414a1 1 0 0 1-.707-1.707l7.586-7.586a1 1 0 0 1 1.414 0z" />
  </svg>
);
