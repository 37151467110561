import { flowRight, isEmpty, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { getCommentsPerPage } from '../../constants/pagination';
import AccountSuspended from '../../components/account-suspended';
import AnimatedLoader from '../../components/animated-loader';
import Button from '../../components/button';
import CommentingDisabled from '../../components/commenting-disabled';
import EmptyStates from '../../components/empty-states';
import Link from '../../components/link/internal-link';
import PostPageHeaderActions from '../../components/post-page-header-actions';
import Pagination from '../../components/pagination-comments';
import PostContent from '../../components/post-content';
import PostHeader from '../../components/post-header';
import PostPageLayout from '../../components/post-page-layout';
import PostPageStats from '../../components/post-page-stats';
import PostTitle from '../../components/post-title';
import TimeAgo from '../../components/time-ago';
import LoadMoreMobilePagination from '../../components/load-more-mobile-pagination';
import { HorizontalSeparator } from '../../components/separator';
import PostCommentList from '../post-comment-list';
import PostMainActions from '../post-main-actions';
import PostPageFooter from '../post-page-footer';
import FooterPosts from '../../components/footer-posts';
import { getCategoryBySlug } from '../../selectors/categories-selectors';
import { getCommentsByPostId } from '../../selectors/comment-selectors';
import { getCurrentUserId } from '../../../common/store/current-user/current-user-selectors';
import { getIsPostLoaded, getIsCommentsLoaded } from '../../selectors/is-loaded-selectors';
import { getIsPostLoading, getIsCommentsLoading } from '../../selectors/is-loading-selectors';
import { getEntityCount } from '../../selectors/pagination-selectors';
import { getPostBySlug } from '../../selectors/post-selectors';
import { getIsDemoMode } from '../../../common/store/instance-values/instance-values-selectors';
import withAuth from '../../hoc/with-auth';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withDeviceType from '../../hoc/with-device-type';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import styles from './post-page.scss';
import { getRouteParams, getPreviousMatches } from '../../../common/router/router-selectors';
import { getLocation, getSectionUrl } from '../../../common/store/location/location-selectors';
import { scrollToComment } from '../../services/scroll-to-comment';
import { getDeepLinkParams } from '../../services/get-deep-link-params';
import {
  ROUTE_CREATE_POST,
  ROUTE_CREATE_QUESTION,
  ROUTE_CATEGORY_CREATE_POST,
  ROUTE_CATEGORY_CREATE_QUESTION,
  ROUTE_POST_EDIT,
} from '../../constants/routes';
import { getIsFooterPostsEnabled } from '../../selectors/app-settings-selectors';
import { isMembersOnly } from '../../../common/services/category-privacy-utils';
import { buildPageUrl, getLastPage } from '../../services/pagination';

export class PostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialPage: props.page,
      currentPage: props.page,
      showCommentListLoader: false,
    };
    this.postContainerRef = React.createRef();
  }

  componentDidMount = () => {
    const { commentToFocusId } = this.props;
    if (commentToFocusId) {
      scrollToComment(commentToFocusId);
    } else if (this.postContainerRef.current) {
      this.postContainerRef.current.focus();
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.commentToFocusId !== this.props.commentToFocusId) {
      scrollToComment(this.props.commentToFocusId);
    }
    if (prevProps.page !== this.props.page) {
      this.setState({ currentPage: this.props.page, showCommentListLoader: false });
    }
  }

  componentWillUnmount = () => {
    this.props.setIsLoaded('comments', this.props.params.postSlug, false);

    this.props.flushTypingThrottled();
    if (!isEmpty(this.props.category)) {
      this.props.emitClosePost(this.props.post._id);
    }

    this.props.clearComments();
  };

  renderPaginationTop = () => {
    const { entityCount, isMobile, post } = this.props;

    if (!isMobile) {
      return (
        <Pagination
          page={this.state.currentPage}
          entityCount={entityCount}
          showPerPage={getCommentsPerPage(isMobile)}
          onChange={this.handlePageChange}
          buildPageUrl={this.buildPageUrl}
          rightSideRender={() => <PostPageHeaderActions post={post} />}
          isTop
        />
      );
    }
  };

  renderPagination = () => {
    const { page, entityCount, isMobile, isCommentsLoaded, isCommentsLoading, t } = this.props;
    const commentsPerPage = getCommentsPerPage(isMobile);
    if (isCommentsLoaded && entityCount > commentsPerPage) {
      if (isMobile) {
        return (
          <LoadMoreMobilePagination
            loadMore={this.loadMoreMobile}
            isLoading={isCommentsLoading}
            hasMore={page < getLastPage(entityCount, commentsPerPage)}
            loadActionText={t('post.load-comments')}
          />
        );
      } else {
        return (
          <Pagination
            page={this.state.currentPage}
            entityCount={entityCount}
            showPerPage={commentsPerPage}
            onChange={this.handlePageChange}
            buildPageUrl={this.buildPageUrl}
          />
        );
      }
    }
  };

  buildPageUrl = (page = 1) => {
    const {
      params: { postSlug, categorySlug },
    } = this.props;
    return buildPageUrl(categorySlug, postSlug, page);
  };

  handlePageChange = ({ page }) => {
    const {
      post,
      params: { categorySlug },
      navigateWithinForum,
    } = this.props;
    navigateWithinForum(buildPageUrl(categorySlug, post.slug, page));
  };

  loadMoreMobile = () => {
    const {
      post,
      params: { categorySlug },
      page,
      navigateWithinForum,
    } = this.props;
    navigateWithinForum(buildPageUrl(categorySlug, post.slug, page + 1));
  };

  renderEditDate = () => {
    const { post, t } = this.props;

    if (post.editedDate) {
      return (
        <p className={styles.edited}>
          <span className={styles.editedTag}>{t('post-page.edited')}&nbsp;</span>
          <TimeAgo time={post.editedDate} className={styles.editedDate} />
        </p>
      );
    }
  };

  renderFooter = () => {
    const { isBlocked, post, t, allComments, isCommentsLoading, isCommentsLoaded } = this.props;
    if (isBlocked || post.isCommentsDisabled) {
      return (
        <CommentingDisabled
          message={t(isBlocked ? 'post-page.commenting-blocked' : 'post-page.commenting-disabled')}
        />
      );
    }

    const showFooter = (!isCommentsLoading && isCommentsLoaded) || !isEmpty(allComments);
    if (!showFooter) {
      return null;
    }

    return (
      <div className={styles.footerContainer}>
        <PostPageFooter postId={post._id} />
      </div>
    );
  };

  renderPost = () => {
    const {
      category,
      post,
      isAnimated,
      borderWidth,
      titleFontClassName,
      page,
      isMobile,
      allComments,
      t,
      postOwnerBadges,
    } = this.props;
    const hasComments = !isEmpty(allComments);

    const initiallyStartedOnFirstPAge = this.state.initialPage === 1;
    if (page > 1 && isMobile /*&& !initiallyStartedOnFirstPAge*/) {
      // TODO after scroll issue is fixed
      return (
        <div className="forum-card-background-color">
          <HorizontalSeparator className={styles.linkToPostSeparator} />
          <Link
            className={classNames(styles.linkToPost, 'forum-text-color')}
            to={`/${category.slug}/${post.slug}`}
            data-hook="back-to-post"
          >
            {t('post-page.back-to-discussion')}
          </Link>
          <HorizontalSeparator className={styles.linkToPostSeparator} />
        </div>
      );
    }

    if (!isMobile && page > 1) {
      return null;
    }

    const postContainerClassName = classNames(
      styles.postContainer,
      { [styles.withComments]: hasComments },
      'forum-card-background-color',
      'forum-card-border-color',
      'forum-text-color',
    );

    return (
      <React.Fragment>
        {isMobile && <HorizontalSeparator />}
        <main
          ref={this.postContainerRef}
          tabIndex="-1"
          className={postContainerClassName}
          style={{ borderWidth }}
          aria-labelledby={`post-header-${post.slug}`}
        >
          <div className={styles.postContentWrapper} data-hook="post-page__post">
            <PostHeader
              origin="post_page"
              type={PostHeader.SINGLE}
              post={post}
              isAnimated={isAnimated}
              isMobilePostHeader={isMobile}
              showDateBelowAvatar={false}
              badge={postOwnerBadges}
              showStrippedBadges={false}
              showFirstSeparator
            />
            <PostTitle
              className={titleFontClassName}
              type={PostTitle.SINGLE}
              title={post.title || ''}
              component="h1"
              id={`post-header-${post.slug}`}
            />
            <div className={styles.postStatsContainer}>
              <PostPageStats
                viewCount={post.viewCount}
                commentCount={post.totalComments}
                isAnimated={isAnimated}
                time={isMobile && post.createdDate}
              />
              {this.renderEditDate()}
            </div>
            <div className={styles.postContent}>
              <PostContent post={post} />
            </div>
            <PostMainActions category={category} post={post} isMobile={isMobile} />
          </div>
        </main>
        {!isMobile && hasComments && (
          <div
            className={classNames(
              styles.postCommentsSeparatorContainer,
              'forum-card-background-color',
              'forum-card-border-color',
            )}
            style={{ borderWidth }}
          >
            <HorizontalSeparator className={styles.postCommentsSeparator} />
          </div>
        )}
      </React.Fragment>
    );
  };

  renderEmpty = () => {
    const { t } = this.props;
    return (
      <EmptyStates
        className={styles.emptyState}
        title={t('post-page.not-found-title')}
        content={t('post-page.not-fount-content')}
        type="post_not_found"
      >
        <Button component={Link} to="/">
          {t('post-page.back-to-forum')}
        </Button>
      </EmptyStates>
    );
  };

  render = () => {
    const {
      params: { postSlug },
      isBlocked,
      category,
      post,
      contentFontClassName,
      isLoading,
      isLoaded,
      isCommentsLoading,
      isCommentsLoaded,
      allComments,
      borderWidth,
      isMobile,
      commentToFocusId,
      isFooterPostsEnabled,
    } = this.props;

    if (isEmpty(post) && !isLoading) {
      return this.renderEmpty();
    }

    if (!isEmpty(category) && isMembersOnly(category) && isBlocked) {
      return <AccountSuspended className={styles.emptyState} />;
    }

    const showCommentsLoader = isCommentsLoading && !isCommentsLoaded && isEmpty(allComments);
    return (
      <PostPageLayout className={classNames(styles.postPage, contentFontClassName)}>
        <AnimatedLoader
          className={styles.innerContainer}
          isLoading={isLoading && !isLoaded && isEmpty(post)}
        >
          {this.renderPaginationTop()}
          {this.renderPost()}
          <AnimatedLoader isLoading={showCommentsLoader} isAnimated>
            {isEmpty(allComments) && !isMobile ? null : (
              <div
                className={classNames(
                  styles.commentsContainer,
                  'forum-card-background-color',
                  'forum-card-border-color',
                )}
                style={{ borderWidth }}
              >
                <PostCommentList
                  isDeepLinked={commentToFocusId}
                  page={this.props.page}
                  postSlug={postSlug}
                  isCommentsLoading={isCommentsLoading || this.state.showCommentListLoader}
                />
                {this.renderPagination()}
              </div>
            )}
            {this.renderFooter()}
          </AnimatedLoader>
        </AnimatedLoader>
        {isFooterPostsEnabled && (
          <div className={styles.footerPosts} data-hook="footer-posts-wrapper">
            <FooterPosts type={FooterPosts.TYPE_RELATED_POSTS} />
          </div>
        )}
      </PostPageLayout>
    );
  };
}

PostPage.propTypes = {
  fetchRepliesToPagePromisified: PropTypes.func.isRequired,
  incrementCommentLikeCount: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  allComments: PropTypes.array,
  focusCommentForm: PropTypes.func.isRequired,
  location: PropTypes.object,
  page: PropTypes.number,
  commentToFocusId: PropTypes.string,
  isMobile: PropTypes.bool,
  emitClosePost: PropTypes.func.isRequired,
  flushTypingThrottled: PropTypes.func.isRequired,
  t: PropTypes.func,
  typing: PropTypes.array,
  ownerId: PropTypes.string,
  entityCount: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  isBlocked: PropTypes.bool,
  params: PropTypes.object,
  isAnimated: PropTypes.object,
  borderWidth: PropTypes.number.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  setIsLoaded: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isCommentsLoading: PropTypes.bool.isRequired,
  isCommentsLoaded: PropTypes.bool.isRequired,
  navigateWithinForum: PropTypes.func.isRequired,
  fetchCommentsWithReplies: PropTypes.func.isRequired,
  clearComments: PropTypes.func.isRequired,
  sectionUrl: PropTypes.string,
  isFooterPostsEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const params = getRouteParams(state);
  const post = getPostBySlug(state, params.postSlug) || {};
  const page = parseInt(params.page, 10) || 1;
  const { commentToFocusId } = getDeepLinkParams(params.deepLinkData);
  const prevRoute = get(getPreviousMatches(state)[1], 'route'); // take 1st member, since 0 is the current route

  return {
    isAnimated: [
      ROUTE_CREATE_POST,
      ROUTE_CREATE_QUESTION,
      ROUTE_CATEGORY_CREATE_POST,
      ROUTE_CATEGORY_CREATE_QUESTION,
      ROUTE_POST_EDIT,
    ].includes(prevRoute),
    sectionUrl: getSectionUrl(state),
    category: getCategoryBySlug(state, params.categorySlug) || {},
    post,
    allComments: getCommentsByPostId(state, post._id),
    typing: state.typing,
    ownerId: getCurrentUserId(state),
    isLoading: getIsPostLoading(state, params.postSlug),
    isLoaded: getIsPostLoaded(state, params.postSlug),
    isCommentsLoading: getIsCommentsLoading(state, params.postSlug),
    isCommentsLoaded: getIsCommentsLoaded(state, params.postSlug),
    isDemoMode: getIsDemoMode(state),
    entityCount: getEntityCount(state, 'comments', post._id),
    page,
    commentToFocusId,
    fetchCommentsWithReplies: actions.fetchCommentsWithReplies,
    fetchRepliesToPagePromisified: actions.fetchRepliesToPagePromisified,
    incrementCommentLikeCount: actions.incrementCommentLikeCount,
    focusCommentForm: () => console.log('focous', 'comment', 'content'),
    emitClosePost: actions.emitClosePost,
    flushTypingThrottled: actions.flushTypingThrottled,
    setIsLoaded: actions.setIsLoaded,
    navigateWithinForum: actions.navigateWithinForum,
    clearComments: actions.clearComments,
    location: getLocation(state),
    params,
    isFooterPostsEnabled: getIsFooterPostsEnabled(state, host.style),
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withDeviceType,
  withFontClassName,
  withCardBorderWidth,
  withAuth,
  withTranslate,
)(PostPage);
