import PropTypes from 'prop-types';
import React from 'react';
import CreatePostHandler from '../../containers/create-post-handler';
import CreatePostCard from '../create-post-card';
import ensureAuth from '../../hoc/ensure-auth';
import { CREATE_NEW_POST } from '../../constants/interactions';

const CreatePostCardSecure = ensureAuth(CreatePostCard);

const CreatePostCta = ({ categorySlug }) => {
  return (
    <CreatePostHandler categorySlug={categorySlug}>
      <CreatePostCardSecure
        actionDetails={{
          action: CREATE_NEW_POST,
          args: [categorySlug ? `/${categorySlug}/create-post` : '/create-post'],
        }}
      />
    </CreatePostHandler>
  );
};

CreatePostCta.propTypes = {
  categorySlug: PropTypes.string,
  isMobileHeaderEnabled: PropTypes.bool,
};

export default CreatePostCta;
