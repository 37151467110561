import PropTypes from 'prop-types';
import React from 'react';
import DesktopHeader from '../desktop-header';
import Breadcrumbs from '../../components/breadcrumbs';
import BreadcrumbsWidget from '../breadcrumbs-widget/breadcrumbs-widget';
import SearchInput from '../../components/search-input/search-input';

const SearchDesktopHeader = ({ routes, params, query, onQueryChange, ...otherProps }) => {
  const searchInput = (
    <SearchInput
      isExpanded
      value={query}
      onClick={onQueryChange}
      inputClassName="desktop-header-search-text-color desktop-header-search-font"
      borderClassName="desktop-header-search-border-background-color"
      iconClassName="desktop-header-search-icon-fill"
    />
  );
  const right = <BreadcrumbsWidget searchInput={searchInput} params={params} />;
  return (
    <DesktopHeader
      left={<Breadcrumbs routes={routes} params={params} />}
      right={right}
      {...otherProps}
    />
  );
};

SearchDesktopHeader.propTypes = {
  routes: PropTypes.array,
  params: PropTypes.object.isRequired,
};

export default SearchDesktopHeader;
