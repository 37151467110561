import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Pagination from '../pagination';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withDividerColor from '../../hoc/with-divider-color';
import styles from './pagination-comments.scss';

const PaginationComments = props => {
  const {
    borderWidth,
    className,
    dividerColor,
    entityCount,
    showPerPage,
    rightSideRender,
    isTop,
  } = props;
  const shouldShowPagination = entityCount > showPerPage;

  return (
    <div
      className={classNames(styles.container, className, 'forum-card-border-color', {
        [styles.isTop]: isTop,
        [styles.withPagination]: shouldShowPagination,
      })}
      style={{
        borderWidth: borderWidth,
        [isTop ? 'borderBottomColor' : 'borderTopColor']: dividerColor,
        [isTop ? 'borderBottomWidth' : 'borderTopWidth']: 1,
      }}
      data-hook={isTop ? 'pagination-top' : 'pagination-bottom'}
    >
      {shouldShowPagination && (
        <div className={styles.innerContainer}>
          <Pagination
            className={styles.pagination}
            arrowClass={styles.arrow}
            innerClass={styles.page}
            activeClass={styles.active}
            changePageOnClick={false}
            {...props}
          />
        </div>
      )}
      {rightSideRender && rightSideRender()}
    </div>
  );
};

PaginationComments.propTypes = {
  entityCount: PropTypes.number,
  showPerPage: PropTypes.number,
  rightSideRender: PropTypes.func,
  isTop: PropTypes.bool,
  borderWidth: PropTypes.number,
  dividerColor: PropTypes.string,
  className: PropTypes.string,
};

export default flowRight(withCardBorderWidth, withDividerColor)(PaginationComments);
