import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { FacebookIcon } from '../icons/facebook-icon';
import { TwitterIcon } from '../icons/twitter-icon';
import { LinkIcon } from '../icons/link-icon';
import styles from './post-share-button.scss';

const ICONS = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  link: LinkIcon,
};

class PostShareButton extends Component {
  handleClick = () => {
    const { onClick, type } = this.props;
    onClick(type);
  };

  render = () => {
    const { type, title } = this.props;
    const iconClassName = classNames(styles.icon, 'forum-icon-fill', {
      'button-hover-fill': type === 'link',
    });
    const Icon = ICONS[type];

    return (
      <button
        className={classNames(styles.button, styles[type])}
        onClick={this.handleClick}
        aria-label={title}
      >
        <Icon className={iconClassName} />
      </button>
    );
  };
}

PostShareButton.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PostShareButton;
