import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import PostListItem from '../post-list-item/post-list-item';
import CommentListItem from '../comment-search-list-item';
import Pagination from '../pagination-regular';
import { HorizontalSeparator } from '../separator';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import styles from './search-results-list.scss';
import { SortingSelectSearchPageDesktop } from '../sorting-select-search';
import { scrollBy } from '../../services/scroll-by';
import { getElementsPositionToRootWindow } from '../../services/get-elements-position';
import { SEARCH_RESULTS_PER_PAGE_DESKTOP } from '../../constants/pagination';
import AnimatedLoader from '../animated-loader/animated-loader';

const POST = 'post';
const COMMENT = 'comment';

class SearchResultsDesktopList extends Component {
  constructor(props) {
    super(props);

    this.state = { page: props.page };
    this.containerRef = React.createRef();
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  //temporary fix - scroll to top on search result page switching
  scrollToTop() {
    const containerTop = this.containerRef.current.getBoundingClientRect().top;
    if (containerTop < 0) {
      getElementsPositionToRootWindow(this.containerRef.current, { top: true }, y => {
        scrollBy(0, y);
      });
    }
  }

  handlePageChange({ page }) {
    this.props.search({
      size: SEARCH_RESULTS_PER_PAGE_DESKTOP,
      offset: (page - 1) * SEARCH_RESULTS_PER_PAGE_DESKTOP,
    });
    this.scrollToTop();
    this.setState({ page });
  }

  renderItem(post, query, onPostLikeClick, onCommentLikeClick) {
    const separator = (
      <div className={styles.separator} key={`${post._id}-separator`}>
        <HorizontalSeparator />
      </div>
    );
    switch (post.type) {
      case POST:
        return [
          <PostListItem
            key={post._id}
            type={PostListItem.SEARCH}
            post={post}
            query={query}
            onLikeClick={onPostLikeClick}
            origin="search_results"
          />,
          separator,
        ];
      case COMMENT:
        return [
          <CommentListItem
            key={post._id}
            type={PostListItem.SEARCH}
            comment={post}
            query={query}
            onCommentLikeClick={onCommentLikeClick}
            onPostLikeClick={onPostLikeClick}
          />,
          separator,
        ];
      default:
        return null;
    }
  }

  renderPagination() {
    const { searchResultsTotal } = this.props;

    return (
      <Pagination
        className={classNames(styles.pagination, 'forum-card-background-color')}
        page={this.state.page}
        entityCount={searchResultsTotal}
        showPerPage={SEARCH_RESULTS_PER_PAGE_DESKTOP}
        onChange={this.handlePageChange}
      />
    );
  }

  render() {
    const {
      posts,
      query,
      onPostLikeClick,
      onCommentLikeClick,
      borderWidth,
      isLoading,
    } = this.props;
    const style = {
      borderWidth,
      marginTop: -borderWidth,
    };

    return (
      <div
        className={classNames(styles.container, 'search-results-list', 'forum-card-border-color')}
        style={style}
        ref={this.containerRef}
      >
        <div className={classNames(styles.header)}>
          {this.renderPagination()}
          <div className={styles.sortSelectContainer}>
            <div className={styles.sortSelect}>
              <SortingSelectSearchPageDesktop />
            </div>
          </div>
        </div>
        <HorizontalSeparator />
        <div className={classNames(styles.wrapper)}>
          <AnimatedLoader isLoading={isLoading} isAnimated>
            {posts.map(post => this.renderItem(post, query, onPostLikeClick, onCommentLikeClick))}
          </AnimatedLoader>
          {this.renderPagination()}
        </div>
      </div>
    );
  }
}

SearchResultsDesktopList.propTypes = {
  posts: PropTypes.array.isRequired,
  borderWidth: PropTypes.number.isRequired,
  query: PropTypes.string,
  onPostLikeClick: PropTypes.func.isRequired,
  onCommentLikeClick: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  search: PropTypes.func.isRequired,
  searchResultsTotal: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default withCardBorderWidth(SearchResultsDesktopList);
