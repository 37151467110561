import { invoke, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Interpolate } from 'react-i18next';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import { MODAL_TYPE_DISCARD_COMMENT } from '../modals/discard-comment-modal/discard-comment-modal-type';
import { getElementsPositionToRootWindow } from '../../services/get-elements-position';
import CurrentUserAvatar from '../../containers/current-user-avatar';
import ProtectedButton from '../../containers/protected-button';
import ButtonGroup from '../button-group';
import TimeAgo from '../time-ago';
import withTranslate from '../../hoc/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import withDividerColor from '../../hoc/with-divider-color';
import withAuth from '../../hoc/with-auth';
import commentFormSettings from '../../services/comment-form-settings';
import RichContentEditor from '../rich-content-editor-async';
import getThemeForComment from '../rich-content-editor/theme-comment';
import getThemeForReply from '../rich-content-editor/theme-reply';
import { isContentStateEmpty } from '../../services/content-state-utils';
import { withFastForm } from '../../../common/components/fast-form';
import { getResolvedModals } from '../../../common/modals/framework/store/modal-selectors';
import { scrollBy } from '../../services/scroll-by';
import { CREATE_COMMENT, CREATE_REPLY } from '../../constants/interactions';
import { REPLY } from '../../constants/form-types';
import { convertContentStateToContent } from '../../services/post-utils';
import { getIsRegistrationWithApproval } from '../../selectors/forum-data-selectors';

import styles from './comment-form.scss';

export const SIZE_COMMENT = 'sizeComment';
export const SIZE_REPLY = 'sizeReply';

const createEditorKey = () => new Date().getTime();

export class CommentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorKey: createEditorKey(),
      discardModalId: Date.now(),
    };
  }

  componentDidMount = () =>
    this.props.scrollIntoView &&
    this.selfElement &&
    getElementsPositionToRootWindow(this.selfElement, { center: true }, y => {
      setTimeout(() => scrollBy(0, y), 100);
    });

  componentDidUpdate(prevProps) {
    const props = this.props;
    if (props.resolvedModals.find(({ resolve: { id } }) => id === this.state.discardModalId)) {
      this.setState({ discardModalId: Date.now() });
      this.onCancel();
    }
    if (prevProps.fastForm.values.content && !props.fastForm.values.content) {
      this.setState({
        editorKey: createEditorKey(),
      });
    }
    if (props.isAuthenticated && !prevProps.isAuthenticated) {
      props.fastForm.resetForm();
    }
  }

  submitAndShowButtonSpinner = () => {
    this.props.fastForm.submit();
  };

  handleContentChange = contentState => {
    this.props.fastForm.changeValue('content')(contentState);

    if (contentState && contentState.getBlockMap) {
      if (!isContentStateEmpty(contentState)) {
        invoke(this.props, 'onChange');
      }
    } else {
      const blocks = contentState.blocks;
      if (blocks[0].text || blocks.length > 1) {
        invoke(this.props, 'onChange');
      }
    }
  };

  renderEditDate = () => {
    const { editedDate } = this.props;

    if (!editedDate) {
      return null;
    }

    return (
      <p className={styles.editDate}>
        <Interpolate i18nKey="comment-form.edited" timeAgo={<TimeAgo time={editedDate} />} />
      </p>
    );
  };

  onCancel = () => {
    if (this.props.resetFormOnCancel) {
      this.props.fastForm.resetForm();
    }
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  isPrimaryButtonDisabled = () => {
    const {
      fastForm: { isSubmitting, isValid },
      shouldDisableButton,
    } = this.props;

    return shouldDisableButton ? isSubmitting || !isValid : false;
  };

  renderButtons = () => {
    const {
      t,
      onCancel,
      forPublicUser,
      resetFormOnCancel,
      alwaysShowCancelButton,
      openModal,
      fastForm,
      isRegistrationWithApproval,
      isAuthenticated,
      postId,
      parentCommentId,
      type,
    } = this.props;
    const primaryButtonDisabled = this.isPrimaryButtonDisabled();
    const primaryButtonProps = {
      disabled: primaryButtonDisabled,
      ...(fastForm.isSubmitting && { 'aria-label': t('comment-form.submitting') }),
      onClick: forPublicUser(this.submitAndShowButtonSpinner),
      isSmall: true,
      isLoading: fastForm.isSubmitting,
    };
    const isSecondaryButtonVisible =
      alwaysShowCancelButton ||
      (!primaryButtonDisabled && (resetFormOnCancel || Boolean(onCancel)));

    const showDiscardModal = () =>
      openModal(MODAL_TYPE_DISCARD_COMMENT, { id: this.state.discardModalId });

    return (
      <ProtectedButton
        actionDetails={
          type === REPLY
            ? {
                action: CREATE_REPLY,
                args: [postId, parentCommentId, convertContentStateToContent(fastForm.values)],
              }
            : {
                action: CREATE_COMMENT,
                args: [postId, convertContentStateToContent(fastForm.values)],
              }
        }
        tagName="div"
        isDisabled={primaryButtonDisabled}
      >
        <ButtonGroup
          primaryButtonText={t('comment-form.publish')}
          primaryButtonProps={primaryButtonProps}
          secondaryButtonText={t('comment-form.cancel')}
          secondaryButtonProps={{ onClick: showDiscardModal, isSmall: true }}
          isSecondaryButtonVisible={
            isRegistrationWithApproval
              ? isAuthenticated && isSecondaryButtonVisible
              : isSecondaryButtonVisible
          }
        />
      </ProtectedButton>
    );
  };

  renderTextEditor() {
    const {
      fastForm: { values },
      t,
      size,
      scrollIntoView,
      onFocus,
    } = this.props;
    return (
      <div className={styles.rceContainer}>
        <RichContentEditor
          key={this.state.editorKey}
          className={classNames(styles.texteditor, 'comment-form__content')}
          placeholder={t(
            size === SIZE_REPLY
              ? 'text-editor.reply-placeholder'
              : 'text-editor.comment-placeholder',
          )}
          onChange={this.handleContentChange}
          initialState={values.content || undefined}
          themeGetter={size === SIZE_COMMENT ? getThemeForComment : getThemeForReply}
          externalModalsEnabled
          focus={scrollIntoView}
          onFocus={onFocus}
          origin="comment"
        />
      </div>
    );
  }

  render = () => {
    const {
      contentFontClassName,
      dividerColor,
      size,
      className,
      isAuthenticated,
      isRegistrationWithApproval,
    } = this.props;
    const containerClassName = classNames(
      styles.commentForm,
      className,
      contentFontClassName,
      styles[size],
      'forum-text-color',
      'comment-form',
      { [styles.withNewEditor]: true },
    );
    return (
      <div
        className={classNames(
          containerClassName,
          !isAuthenticated && !isRegistrationWithApproval && styles.topPadding,
        )}
        data-hook="comment-form"
        ref={r => (this.selfElement = r)}
      >
        <div className={styles.header}>
          {isAuthenticated && <CurrentUserAvatar type={CurrentUserAvatar.MEDIUM} />}
        </div>
        {this.renderEditDate()}
        <div className={classNames(styles.content)} style={{ borderColor: dividerColor }}>
          {this.renderTextEditor()}
        </div>
        <div className={styles.buttonContainer}>{this.renderButtons()}</div>
      </div>
    );
  };
}

CommentForm.propTypes = {
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  submitting: PropTypes.bool,
  t: PropTypes.func,
  contentFontClassName: PropTypes.string.isRequired,
  className: PropTypes.string,
  shouldDisableButton: PropTypes.bool,
  editedDate: PropTypes.string,
  forPublicUser: PropTypes.func,
  dividerColor: PropTypes.string,
  size: PropTypes.string,
  resetFormOnCancel: PropTypes.bool,
  alwaysShowCancelButton: PropTypes.bool,
  scrollIntoView: PropTypes.bool,
  resolvedModals: PropTypes.array,
  openModal: PropTypes.func,
  fastForm: PropTypes.object.isRequired,
  type: PropTypes.string,
  onFocus: PropTypes.func,
  isRegistrationWithApproval: PropTypes.bool,
};

CommentForm.defaultProps = {
  shouldDisableButton: true,
  size: SIZE_COMMENT,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  return {
    resolvedModals: getResolvedModals(state),
    openModal: actions.openModal,
    isRegistrationWithApproval: getIsRegistrationWithApproval(state),
  };
};

export default flowRight(
  withFastForm(commentFormSettings),
  connect(mapRuntimeToProps),
  withTranslate,
  withFontClassName,
  withFontClassName,
  withDividerColor,
  withAuth,
)(CommentForm);
