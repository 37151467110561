import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const SubscribeIcon = ({ isSubscribed, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14"
    viewBox="0 0 16 14"
    style={{
      fillRule: 'evenodd',
    }}
    className={classNames({
      'forum-secondary-fill-color': isSubscribed,
    })}
    {...props}
  >
    <path
      data-name="Rectangle 1 copy"
      d="M2893,526v-6h2v6h-2Zm0-14h2v6h-2v-6Zm-1.74,8.175-3.85,3.846-1.33-1.331,2.69-2.69H2879v-2h10.09l-2.77-2.768,1.41-1.414,4.95,4.95-1.41,1.414Z"
      transform="translate(-2879 -512)"
    />
  </svg>
);

SubscribeIcon.propTypes = {
  isSubscribed: PropTypes.bool,
};
