import { connect } from '../../../common/components/runtime-context';
import { scrollToPostCommentForm } from '../../services/scroll-to-post-comment-form';
import { LIKE_POST } from '../../constants/interactions';
import PostMainActions from '../../components/post-main-actions';
import { MODAL_TYPE_SHARE_POST } from '../../components/modals/share-post-modal/share-post-modal-type';

const mapRuntimeToProps = (state, ownProps, actions) => ({
  onReplyClick: scrollToPostCommentForm,
  onShareClick: () =>
    actions.openModal(MODAL_TYPE_SHARE_POST, {
      categoryId: ownProps.category._id,
      categorySlug: ownProps.category.slug,
      postId: ownProps.post._id,
      postSlug: ownProps.post.slug,
    }),
  onLikeClick: () => {
    actions.userEventsClickLike({
      type: 'post',
      _id: ownProps.post._id,
      isLiked: ownProps.post.isLiked,
    });
    actions.interactionStarted(LIKE_POST);
    return actions.incrementPostLikeCount(ownProps.post._id);
  },
});

export default connect(mapRuntimeToProps)(PostMainActions);
