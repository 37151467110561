import { withSentryErrorBoundary } from 'native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import { withStyles } from 'native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import AppRoot from './containers/app-root';
import InvalidAppStatePage from './components/invalid-app-state-page';
import { withWebpackPublicPath } from '../common/hoc/with-webpack-public-path';
import { withLayoutUpdate } from '../common/hoc/with-layout-update';
import { SENTRY_COMPONENT_SCRIPT_DSN } from '../common/constants/sentry-viewer-dsn';
import { withInvalidAppState } from '../common/hoc/with-invalid-app-state';
import { withDevTools } from './components/dev-tools/with-dev-tools';

const withStylesConfig = {
  cssPath: ['forum-app-viewer.stylable.bundle.css', 'forum-app-viewer.css'],
};

const SENTRY_CONFIG = {
  dsn: SENTRY_COMPONENT_SCRIPT_DSN,
  config: {
    environment: `ooi-forum-app-${process.env.NODE_ENV}`,
  },
};

const FallbackPage = withStyles(InvalidAppStatePage, withStylesConfig);

export default {
  component: withSentryErrorBoundary(
    withLayoutUpdate(
      withInvalidAppState(
        withWebpackPublicPath(withDevTools(withStyles(AppRoot, withStylesConfig))),
        FallbackPage,
      ),
    ),
    SENTRY_CONFIG,
    FallbackPage,
  ),
};
